import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { SocketService } from '../../../services/socket.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-contact-style-one',
  templateUrl: './contact-style-one.component.html',
  styleUrls: ['./contact-style-one.component.scss']
})
export class ContactStyleOneComponent implements OnInit {
  AddForm: FormGroup;
  loan: any;
  product: any
  fname: any

  options = [
    { label: 'Mortgage Loans' },
    { label: 'Car Loans' },
    { label: 'Home Loans' },
    { label: 'SME Loans' },
    { label: 'Business Loans' },
    { label: 'Life Insurance' },
    { label: 'General Insurance' },
  ];

  loanoption = [
    {
      loan: '0L to 5L'
    },
    {
      loan: '5L to 10L'

    },
    {
      loan: '10L to 25L'

    },
    {
      loan: '25L and Above'
    },
  ]

  constructor(public FormBuilder: FormBuilder, private service: SocketService, private ToastrService: ToastrService,private NgxSpinnerService: NgxSpinnerService) {
    this.createForm();
  }

  createForm() {
    this.AddForm = this.FormBuilder.group({
      fname: new FormControl(null, [Validators.required, Validators.pattern(/^(?!\s*$).+/)]),
      lname: [null, [Validators.required, Validators.pattern(/^(?!\s*$).+/)]],
      email: [null, [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      phone: [null, [Validators.required, Validators.pattern(/^[0-9]+$/),
      Validators.minLength(10),]],
      product: [null, [Validators.required]],
      loan: [null, [Validators.required,]],
    });
  }

  ngOnInit(): void {
  }

  onadd() {
    this.NgxSpinnerService.show();
    var url = 'Login/vforuwebsitecontactus';
    this.service.post(url, this.AddForm.value).subscribe((result: any) => {
      console.log(result)
      if (result == false) {
        this.ToastrService.warning("Error Occured");
        this.NgxSpinnerService.hide();
      } else {
        this.ToastrService.success("Mail sent successfully. Our Team wil get back to you shortly!!");
        this.AddForm.reset();
        this.NgxSpinnerService.hide();
      }
    });
  }

}
