<section class="services-details-area ptb-20" id="mortgage">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="services-details-desc">
                    <section class="about-area ptb-100">
                        <div class="container-fluid">
                            <div class="row" style="margin-top: -46px;" >
                                <div class="col-lg-6 col-md-12">
                                    <div class="about-image-warp image-four">

                                    </div>
                                </div>
 
                                <div class="col-lg-6 col-md-12" >
                                    <div class="about-content warp features-list">
                                        <div class="services-details-features">
                                            <div class="row mx-auto" style="width: 399px;">
                                                <div class="col-sm" style="margin-top: -53px;">
                                                    <ul class="features-list" style="font-size: 19px;">
                                                        <h3>Mortgage Loans</h3>
                                                        <li><i class="flaticon-check"></i> Banking products upto 3crs without income tax.</li>
                                                        <li><i class="flaticon-check"></i> Rental based Mortgage Loans upto 15crs.</li>
                                                        <li><i class="flaticon-check"></i> Income based Mortgage Loans upto 50crs.</li>
                                                        <h3>Residential Property Loan</h3>
                                                        <li><i class="flaticon-check"></i> Commercial Property Loans.
                                                        </li>
                                                        <li><i class="flaticon-check"></i> Industrial Property Loans.
                                                        </li>
                                                        <li><i class="flaticon-check"></i> School and College Property Loan.</li>
                                                        <li><i class="flaticon-check"></i> Hospital Property Loan.</li>
                                                        <li><i class="flaticon-check"></i> Hostel Property Loan.</li>
                                                        <li><i class="flaticon-check"></i> Marriage Hall Property Loan.</li>
                                                        <li><i class="flaticon-check"></i> Vacant Land in city limits.</li>

                                                    </ul>
                                                </div>



                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div class="section-title "  id="car">
                        <div class="bar" style="width: 100%;"></div>
                    </div>

                    <section class="about-area pb-100">
                        <div class="container-fluid">
                            <div class="row align-items-center">
                                <div class="col-lg-6 col-md-12">
                                    <div class="about-content warp features-list">
                                        <div class="services-details-features">
                                            <div class="row align-items-center">
                                                <div class="col-sm" style="margin-top: -71px;">
                                                    <ul class="features-list" style="font-size: 19px;">
                                                        <h3>Car Loans</h3>
                                                        <li><i class="flaticon-check"></i>New cars.</li>
                                                        <li><i class="flaticon-check"></i>Used cars.</li>
                                                        <li><i class="flaticon-check"></i>Refinancing of
                                                            existing car loan top-up of upto 200% from value.</li>
                                                        <h3>Key Features</h3>
                                                        <li><i class="flaticon-check"></i>Max funding up to 100%.</li>
                                                        <li><i class="flaticon-check"></i>Loan tenure up to 7 years.
                                                        </li>
                                                        <li><i class="flaticon-check"></i>Special Pre Approved Loan
                                                            Scheme for HDFC A/C
                                                            holder.</li>
                                                        <li><i class="flaticon-check"></i>On Road Funding on select
                                                            Profile Customers.</li>
                                                        <li><i class="flaticon-check"></i>Insurance Funding added with
                                                            loan.</li>
                                                        <li><i class="flaticon-check"></i>Up to 200% loan on car value
                                                            on take over loans.
                                                        </li>
                                                        <li><i class="flaticon-check"></i>Speedy Approval.</li>
                                                        <li><i class="flaticon-check"></i>Doorstep Service with
                                                            transparency and
                                                            friendliness.</li>

                                                    </ul>
                                                </div>



                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <div class="about-image">
                                        <img src="assets/img/car_loan (2).jpg" alt="image" style="max-width: 100%;">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div class="section-title " id="home">
                        <div class="bar" style="width: 100%;"></div>
                    </div>

                    <div class="services-details-features" >
                        <h3>Home Loans</h3>
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-12">
                                <img src="assets/img/home_loan_block.png" alt="image" style="margin-left: 41px;">
                            </div >
                            <div class="col-lg-6 col-md-12" id = "home">
                                <div class="features-image">
                                    <img src="assets/img/home_loan.png" alt="image" style="margin-left: 47px;">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="section-title " id="sme">
                        <div class="bar" style="width: 100%;"></div>
                    </div>

                    <section class="about-area pb-100" >
                        <div class="container-fluid">
                            <div class="row align-items-center">
                                <div class="col-lg-6 col-md-12">
                                    <div class="about-image">
                                        <img src="assets/img/sme_service.png" alt="image">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <div class="about-content style-width">
                                        <h3>SME Loans</h3>
                                        <div class="bar"></div>

                                        <div class="about-inner-content">
                                            <div class="icon">
                                                <i class="flaticon-check"></i>
                                            </div>
                                            <p style="font-size: 19px;" id = "sme">Empowering Small Medium enterprises (SMEs) by offering personalized financial solutions tailored to their specific needs. As a reliable partner in business growth, V for U Financial Service aims to support SMEs in achieving success through customized financial services that address their unique challenges and contribute to their overall development.</p>
                                        </div>
                                        <div class="about-inner-content">
                                            <div class="icon">
                                                <i class="flaticon-check"></i>
                                            </div>
                                            <p style="font-size: 19px;" id = "sme">Loans for businesses offered without any need for submission of IT related documents.</p>
                                        </div>
                                        <div class="about-inner-content">
                                            <div class="icon">
                                                <i class="flaticon-check"></i>
                                            </div>
                                            <p style="font-size: 19px;" id = "sme">SME loans are also granted to businesses by just submitting their GST returns filing statments.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div class="section-title " id="business">
                        <div class="bar" style="width: 100%;"></div>
                    </div>

                    <section class="about-area pb-100" >
                        <div class="container-fluid">
                            <div class="row align-items-center">
                                <div class="col-lg-6 col-md-12">
                                    <div class="about-content style-width">
                                        <h3 id = "business">Business Loans</h3>
                                        <div class="bar"></div>

                                        <div class="about-inner-content">
                                            <div class="icon">
                                                <i class="flaticon-check"></i>
                                            </div>
                                            <p style="font-size: 19px;" >Unlock the potential of your business with V for U Financial
                                                 Service. Our tailored business loans are designed to meet your specific needs and 
                                                 propel you towards success. Whether you're looking to expand operations, 
                                                 invest in new equipment, or manage cash flow, our personalized financial 
                                                 solutions are crafted to support your unique business goals. 
                                                 Partner with V for U to access the funding you need and take your business to new heights</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-12" >
                                    <div class="about-image">
                                        <img src="assets/img/business_service.png" alt="image" style="margin-left: 46px;">
                                    </div>
                                </div>
                            </div>
                        </div >
                    </section>

                    <div class="section-title " id="life">
                        <div class="bar" style="width: 100%;"></div>
                    </div>

                    <div class="text-result">
                        <h3>We do all types of insurance depends on customers needs</h3>
                    </div>
                    <section class="about-area pb-100"  >
                        <div class="container-fluid">
                            <div class="row align-items-center">
                                <div class="col-lg-6 col-md-12">
                                    <div class="about-image">
                                        <img src="assets/img/Insurance (1).jpg" alt="image" style="max-width: 100%; margin-top: -1px;">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <div class="about-content warp features-list">
                                        <div class="services-details-features">
                                            <div class="row align-items-center" >
                                                <div class="col-sm" style="margin-top: -50px;">
                                                    <ul class="features-list" style="font-size: 19px;">
                                                        <h3 id="general">Life Insurance</h3>
                                                        <li><i class="flaticon-check"></i>Conventional Insurance Plans
                                                        </li>
                                                        <li><i class="flaticon-check"></i>Term Insurance</li>
                                                        <li><i class="flaticon-check"></i>ULIP Insurance</li>
                                                        <li><i class="flaticon-check"></i>Guaranteed Income Plan</li>
                                                        <h3 >General Insurance</h3>
                                                        <li><i class="flaticon-check"></i>Motor Insurance</li>
                                                        <li><i class="flaticon-check"></i>Mediclaim Insurance</li>
                                                        <li><i class="flaticon-check"></i>Fire & Burglary Insurance</li>
                                                        <li><i class="flaticon-check"></i>House Holder Policy</li>
                                                        <li><i class="flaticon-check"></i>Travel Insurance</li>
                                                        <li><i class="flaticon-check"></i>Shopkeeper & Godown Insurance
                                                        </li>
                                                        <li><i class="flaticon-check"></i>Marine Insurance</li>
                                                       

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <!-- <div class="services-details-faq">
                        <ul class="accordion">
                            <li class="accordion-item">
                                <a class="accordion-title" href="javascript:void(0)"><i class='bx bx-plus'></i> What is digital marketing?</a>
                                <p class="accordion-content">Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </li>
                            
                            <li class="accordion-item">
                                <a class="accordion-title" href="javascript:void(0)"><i class='bx bx-plus'></i> Would my business benefit from digital marketing?</a>
                                <p class="accordion-content">Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </li>
    
                            <li class="accordion-item">
                                <a class="accordion-title" href="javascript:void(0)"><i class='bx bx-plus'></i> What’s the biggest mistake digital marketers make?</a>
                                <p class="accordion-content">Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </li>
                        </ul>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</section>