<!-- Page Banner -->
<div class="page-banner-area item-bg1">
    <div class="container">
        <div class="page-banner-content">
            <h2>Services</h2>
            <ul>
                <li><a routerLink="/home-four">Home</a></li>
                <li>Services</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Banner -->



<!-- Industries -->
<section class="industries-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Helping Businesses in All Domains</h2>
            <div class="bar"></div>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-digital-marketing-2"></i>
                    <h3>Mortgage Loans</h3>
                    <a (click)="scrollToDiv(mortgage)" ></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-digital-marketing-1"></i>
                    <h3>Car Loans</h3>
                    <a (click)="scrollToDiv(car)"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-bank"></i>
                    <h3>Home Loans</h3>
                    <a (click)="scrollToDiv(home)"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-stock-market"></i>
                    <h3>Business Loans</h3>
                    <a (click)="scrollToDiv(business)"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-employee"></i>
                    <h3>SME Loans</h3>
                    <a (click)="scrollToDiv(sme)"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-stethoscope"></i>
                    <h3>Life Insurance</h3>
                    <a (click)="scrollToDiv(life)"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-graduation-cap"></i>
                    <h3>General Insurance</h3>
                    <a (click)="scrollToDiv(general)"></a>
                </div>
            </div>

        </div>
    </div>
</section>
<!-- End Industries -->

<!-- Services routerLink="/services-details" -->
<section class="services-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-6" >
                <div class="single-services-box">
                    <div class="icon" #mortgage>
                        <i class="flaticon-digital-marketing-2"></i>
                    </div>
                    <h3><a >Mortgage Loans</a></h3>
                    <p style="font-size: 19px;">Choose from a range of options, including rental and income-based loans, to finance up to 50crs and grow your financial portfolio today.</p>
                    <a (click)="handleClick('details1')"  class="services-btn" >Read More <i class="flaticon-right-arrow"></i></a>
                    
                </div>
            </div>

            <div class="col-lg-12 col-md-6" >
                <div class="single-services-box">
                    <div class="icon" #car>
                        <i class="flaticon-digital-marketing-1"></i>
                    </div>
                    <h3><a >Car Loans</a></h3>
                    <p style="font-size: 19px;">Upgrade your ride with a car loan that offers options for new and used vehicles, as well as refinancing and takeovers of existing loans.</p>
                    <a routerLink="/services-details" class="services-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-12 col-md-6" >
                <div class="single-services-box">
                    <div class="icon" #home>
                        <i class="flaticon-bank"></i>
                    </div>
                    <h3><a >Home Loans</a></h3>
                    <p style="font-size: 19px;">Achieve your dream home with the financial support of Home Loan. 
                    Whether it's a new property, construction, or renovation, we have you covered.</p>
                    <a routerLink="/services-details" fragment="home" class="services-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-12 col-md-6" >
                <div class="single-services-box">
                    <div class="icon" #business>
                        <i class="flaticon-stock-market"></i>
                    </div>
                    <h3><a routerLink="/services-details">Business Loans</a></h3>
                    <p style="font-size: 19px;">Take your business to the next level with our tailored loans designed to help your company grow and succeed. 
                    Invest in your future today.</p>
                    <a routerLink="/services-details" class="services-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-12 col-md-6">
                <div class="single-services-box">
                    <div class="icon"  #sme>
                        <i class="flaticon-employee"></i>
                    </div>
                    <h3><a routerLink="/services-details">SME Loans</a></h3>
                    <p style="font-size: 19px;">Grow your small or medium business with our tailored loan options and competitive interest rates. 
                    Take your business to the next level with SME loan.</p>
                    <a routerLink="/services-details" class="services-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-12 col-md-6" >
                <div class="single-services-box">
                    <div class="icon" #life>
                        <i class="flaticon-stethoscope"></i>
                    </div>
                    <h3><a routerLink="/services-details">Life Insurance</a></h3>
                    <p style="font-size: 19px;">Protect your life and assets with insurance options that fit your needs. 
                    Experience peace of mind knowing you have the right coverage for any situation.</p>
                    <a routerLink="/services-details" class="services-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-12 col-md-6" >
                <div class="single-services-box">
                    <div class="icon" #general>
                        <i class="flaticon-graduation-cap"></i>
                    </div>
                    <h3><a routerLink="/services-details">General Insurance</a></h3>
                    <p style="font-size: 19px;">Agreement between a policyholder and insurer wherein the insurance company protects your valuable assets from fire, theft, burglary, or any other unfortunate accident.</p>
                    <a routerLink="/services-details" class="services-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape1"><img src="assets/img/default-shape/default-shape1.png" alt="image"></div>
        <div class="shape2"><img src="assets/img/default-shape/default-shape2.png" alt="image"></div>
        <div class="shape3"><img src="assets/img/default-shape/default-shape3.png" alt="image"></div>
        <div class="shape4"><img src="assets/img/default-shape/default-shape4.png" alt="image"></div>
    </div>
</section>
<!-- End Services -->