import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { SocketService } from '../../../services/socket.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent {
  AddForm: FormGroup;
  formDataObject: any = new FormData();
  gender: any;

  constructor(public FormBuilder: FormBuilder, private service: SocketService,private ToastrService: ToastrService,private NgxSpinnerService: NgxSpinnerService) {
    this.createForm();
  }

  createForm() {
    this.AddForm = this.FormBuilder.group({
      fname: new FormControl(null, [Validators.required, Validators.pattern(/^(?!\s*$).+/)]),
      lname: [null, [Validators.required, Validators.pattern(/^(?!\s*$).+/)]],
      email: [null, [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      phone: [null, [Validators.required, Validators.pattern(/^[0-9]+$/),
      Validators.minLength(10),]],
      resume: [null, [Validators.required,]],
      gender: [null,[Validators.required,]],
      department: [null, [Validators.required, Validators.pattern(/^(?!\s*$).+/)]],
      YearOfExp: [null, [Validators.required, Validators.pattern(/^(?!\s*$).+/)]],
      YearOfpass: [null, [Validators.required, Validators.pattern(/^(?!\s*$).+/)]],
      Employee: [null, [Validators.required, Validators.pattern(/^(?!\s*$).+/)]],
      Skill: [null, [Validators.required, Validators.pattern(/^(?!\s*$).+/)]],
    });
  }

  onadd() {
    this.NgxSpinnerService.show();
    const fileInput: HTMLInputElement | null = document.getElementById('inputGroupFile01') as HTMLInputElement;

    if (fileInput) {
      const files: FileList | null = fileInput.files;

      if (files && files.length > 0) {
        this.formDataObject.append('files', files[0]);
        this.formDataObject.append('fname', this.AddForm.value.fname);
        this.formDataObject.append('lname', this.AddForm.value.lname);
        this.formDataObject.append('email', this.AddForm.value.email);
        this.formDataObject.append('phone', this.AddForm.value.phone);
        this.formDataObject.append('gender', this.AddForm.value.gender);
        this.formDataObject.append('department', this.AddForm.value.department);
        this.formDataObject.append('yop', this.AddForm.value.YearOfpass);
        this.formDataObject.append('experience', this.AddForm.value.YearOfExp);
        this.formDataObject.append('current_employer', this.AddForm.value.Employee);
        this.formDataObject.append('skill_set', this.AddForm.value.Skill);

        
        var url = "Login/vforuwebsitecareer"
        this.service.postfile(url,this.formDataObject).subscribe((result: any) => {
          if (result.status == false) {            
            this.ToastrService.warning(result.message);
            this.NgxSpinnerService.hide();
          } else {
            this.ToastrService.success(result.message);
            this.AddForm.reset();
            this.NgxSpinnerService.hide();
          }
        });
      } else {
        console.log('No file selected');
      }
    } else {
      console.log('File input not found');
    }
  }
}
