import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-about-style-two',
  templateUrl: './about-style-two.component.html',
  styleUrls: ['./about-style-two.component.scss']
})
export class AboutStyleTwoComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  serviceClick(value: string) {
    var url = '/contact-1';
    this.router.navigateByUrl(url).then(() => {
            const element = document.getElementById(value);
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    });
  }

}
