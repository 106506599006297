<!-- Page Banner -->
<div class="page-banner-area item-bg4">
    <div class="container">
        <div class="page-banner-content">
            <h2>Services Two</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Services Two</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Banner -->

<!-- Services -->
<section class="services-area bg-fafafa pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services-item">
                    <div class="image">
                        <a routerLink="/services-details">
                            <img src="assets/img/services/services-7.jpg" alt="image">
                        </a>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/services-details">Creative Web Design</a></h3>
                        <span>Web Design</span>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6">
                <div class="single-services-item">
                    <div class="image">
                        <a routerLink="/services-details">
                            <img src="assets/img/services/services-8.jpg" alt="image">
                        </a>
                    </div>
                    <div class="content">
                        <h3>
                            <a routerLink="/services-details">Digital Agency</a>
                        </h3>
                        <span>Agency</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services-item">
                    <div class="image">
                        <a routerLink="/services-details">
                            <img src="assets/img/services/services-9.jpg" alt="image">
                        </a>
                    </div>
                    <div class="content">
                        <h3>
                            <a routerLink="/services-details">Complex Dashboard</a>
                        </h3>
                        <span>Solutions</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services-item">
                    <div class="image">
                        <a routerLink="/services-details">
                            <img src="assets/img/services/services-10.jpg" alt="image">
                        </a>
                    </div>
                    <div class="content">
                        <h3>
                            <a routerLink="/services-details">Software Engineers</a>
                        </h3>
                        <span>Analysis</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services-item">
                    <div class="image">
                        <a routerLink="/services-details">
                            <img src="assets/img/services/services-11.jpg" alt="image">
                        </a>
                    </div>
                    <div class="content">
                        <h3>
                            <a routerLink="/services-details">Marketing Agency</a>
                        </h3>
                        <span>Marketing</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services-item">
                    <div class="image">
                        <a routerLink="/services-details">
                            <img src="assets/img/services/services-12.jpg" alt="image">
                        </a>
                    </div>
                    <div class="content">
                        <h3>
                            <a routerLink="/services-details">Data Analysis</a>
                        </h3>
                        <span>Explanation</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape1"><img src="assets/img/default-shape/default-shape1.png" alt="image"></div>
        <div class="shape2"><img src="assets/img/default-shape/default-shape2.png" alt="image"></div>
        <div class="shape3"><img src="assets/img/default-shape/default-shape3.png" alt="image"></div>
        <div class="shape4"><img src="assets/img/default-shape/default-shape4.png" alt="image"></div>
    </div>
</section>
<!-- End Services -->

<!-- Industries -->
<section class="industries-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Industries We Work For</span>
            <h2>Helping Businesses in All Domains</h2>
            <div class="bar"></div>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-digital-marketing-2"></i>
                    <h3>Digital Marketing</h3>
                    <a routerLink="/services-details"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-bank"></i>
                    <h3>Banking Services</h3>
                    <a routerLink="/services-details"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-graduation-cap"></i>
                    <h3>Education Services</h3>
                    <a routerLink="/services-details"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-stock-market"></i>
                    <h3>Business Consult</h3>
                    <a routerLink="/services-details"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-employee"></i>
                    <h3>Entrepreneur</h3>
                    <a routerLink="/services-details"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-waiter"></i>
                    <h3>Restaurant</h3>
                    <a routerLink="/services-details"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-stethoscope"></i>
                    <h3>Health Services</h3>
                    <a routerLink="/services-details"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-user"></i>
                    <h3>Social Network</h3>
                    <a routerLink="/services-details"></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Industries -->