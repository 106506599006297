import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pricing-style-one',
  templateUrl: './pricing-style-one.component.html',
  styleUrls: ['./pricing-style-one.component.scss']
})
export class PricingStyleOneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
