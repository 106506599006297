<app-preloader></app-preloader>
<app-navbar></app-navbar>
<router-outlet></router-outlet>
<app-footer></app-footer>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-spin-clockwise" [fullScreen]="true">
    <p style="color: white;">Loading...</p>
  </ngx-spinner>
  
<div class="others-options d-flex align-items-center justify-content-end fixed-button-container" >
    <div class="option-item">
        <a (click)="contact()"  class="default-btn fixed-button rotate-90">Service Enquiry</a>
    </div>
</div>