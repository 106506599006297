<!-- Page Banner -->
<div class="page-banner-area item-bg2">
    <div class="container">
        <div class="page-banner-content">
            <h2>Team</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Team</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Banner -->

<!-- Team -->
<section class="team-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Team Member</span>
            <h2>Our Expert Team</h2>
            <div class="bar"></div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team/team-6.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Alex Maxwel</h3>
                        <span>CEO & Founder</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team/team-7.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Justin Roberto</h3>
                        <span>Head Of Marketing</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team/team-8.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Louis Agassiz</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team/team-9.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Carl Anderson</h3>
                        <span>Android/IOS Developer</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team/team-10.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Justin Roberto</h3>
                        <span>Chief Executive Officer</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team/team-11.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Edward Bold</h3>
                        <span>Marketing Support</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Team -->