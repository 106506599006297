<!-- Main Banner -->
<div class="main-banner">
    <div class="main-banner-item item-four">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container-fluid">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="main-banner-content">
                                <h1>Digital Marketing Best Solution</h1>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                <form class="banner-form">
                                    <input type="email" class="form-control" placeholder="Enter your Email">
                                    <button type="submit">Get Your Report</button>
                                </form>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="main-banner-image">
                                <img src="assets/img/banner/banner-image-4.jpg" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Main Banner -->

<!-- Features -->
<section class="features-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-width">
            <span>Care Features</span>
            <h2>Create Awesome Service With Our Tools</h2>
            <div class="bar"></div>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="features-image-warp">
                    <img src="assets/img/features/features-2.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="single-features-box top-1">
                            <div class="icon">
                                <i class="flaticon-promotion"></i>
                            </div>
                            <h3><a routerLink="/">Marketing Analysis</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-features-box top-2">
                            <div class="icon">
                                <i class="flaticon-speed"></i>
                            </div>
                            <h3><a routerLink="/">Website Optimization</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-features-box top-3">
                            <div class="icon">
                                <i class="flaticon-email"></i>
                            </div>
                            <h3><a routerLink="/">Email Marketing</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-features-box top-4">
                            <div class="icon">
                                <i class="flaticon-network"></i>
                            </div>
                            <h3><a routerLink="/">Social Media Marketing</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Features -->

<!-- About -->
<section class="about-area pb-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="about-image-warp image-three">
                    <a href="https://www.youtube.com/watch?v=ODfy2YIKS1M" class="video-btn popup-youtube">
                        <i class='bx bx-play'></i>
                    </a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content warp">
                    <span>About Us</span>
                    <h3>We Help Companies to Generate Leads and Increase in Sales</h3>
                    <div class="bar"></div>
                    <strong>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt labore  dolore magna aliqua.</strong>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                    <div class="about-inner-content">
                        <div class="icon">
                            <i class="flaticon-check"></i>
                        </div>
                        <h4>Online Presence</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>

                    <div class="about-inner-content">
                        <div class="icon">
                            <i class="flaticon-check"></i>
                        </div>
                        <h4>Marketing Strategy</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>

                    <div class="about-inner-content">
                        <div class="icon">
                            <i class="flaticon-check"></i>
                        </div>
                        <h4>Promote local Sale</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                    
                    <div class="about-btn">
                        <a routerLink="/#" class="default-btn">Learn More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>   
<!-- End About -->

<!-- Services -->
<section class="services-area bg-fafafa pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Services we provided</span>
            <h2>Our Digital Marketing Services</h2>
            <div class="bar"></div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services-item">
                    <div class="image">
                        <a routerLink="/services-details">
                            <img src="assets/img/services/services-7.jpg" alt="image">
                        </a>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/services-details">Creative Web Design</a></h3>
                        <span>Web Design</span>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6">
                <div class="single-services-item">
                    <div class="image">
                        <a routerLink="/services-details">
                            <img src="assets/img/services/services-8.jpg" alt="image">
                        </a>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/services-details">Digital Agency</a></h3>
                        <span>Agency</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services-item">
                    <div class="image">
                        <a routerLink="/services-details">
                            <img src="assets/img/services/services-9.jpg" alt="image">
                        </a>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/services-details">Complex Dashboard</a></h3>
                        <span>Solutions</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services-item">
                    <div class="image">
                        <a routerLink="/services-details">
                            <img src="assets/img/services/services-10.jpg" alt="image">
                        </a>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/services-details">Software Engineers</a></h3>
                        <span>Analysis</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services-item">
                    <div class="image">
                        <a routerLink="/services-details">
                            <img src="assets/img/services/services-11.jpg" alt="image">
                        </a>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/services-details">Marketing Agency</a></h3>
                        <span>Marketing</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services-item">
                    <div class="image">
                        <a routerLink="/services-details">
                            <img src="assets/img/services/services-12.jpg" alt="image">
                        </a>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/services-details">Data Analysis</a></h3>
                        <span>Explanation</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape1"><img src="assets/img/default-shape/default-shape1.png" alt="image"></div>
        <div class="shape2"><img src="assets/img/default-shape/default-shape2.png" alt="image"></div>
        <div class="shape3"><img src="assets/img/default-shape/default-shape3.png" alt="image"></div>
        <div class="shape4"><img src="assets/img/default-shape/default-shape4.png" alt="image"></div>
    </div>
</section>
<!-- End Services -->

<!-- Digital Experience -->
<section class="digital-experience-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Digital Experience</span>
            <h2>Outstanding Digital Experience</h2>
            <div class="bar"></div>
        </div>

        <div class="tab digital-experience-tab">
            <ul class="tabs">
                <li>
                    <a href="#">
                        <span>
                            <i class="flaticon-analysis"></i>
                            Real-Time Analytics
                        </span>
                    </a>
                </li>
                <li class="bg-5cbd12">
                    <a href="#">
                        <span>
                            <i class="flaticon-profit"></i>
                            Pay-Per-Click
                        </span>
                    </a>
                </li>
                <li class="bg-e2851b">
                    <a href="#">
                        <span>
                            <i class="flaticon-digital-marketing"></i>
                            Online Marketing
                        </span>
                    </a>
                </li>
                <li class="bg-04b893">
                    <a href="#">
                        <span>
                            <i class="flaticon-email-marketing"></i>
                            Email Marketing
                        </span>
                    </a>
                </li>
                <li class="bg-785eda">
                    <a href="#">
                        <span>
                            <i class="flaticon-network"></i>
                            Social Marketing
                        </span>
                    </a>
                </li>
                <li class="bg-d0465a">
                    <a href="#">
                        <span>
                            <i class="flaticon-digital-marketing-2"></i>
                            Digital Marketing
                        </span>
                    </a>
                </li>
            </ul>

            <div class="tab_content">
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="digital-experience-content">
                                <h3>Real-Time Analytics</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.</p>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>Super Responsive</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>High Security</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>Optimal Choice</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="digital-experience-image">
                                <img src="assets/img/digital-experience/digital-experience-1.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="digital-experience-content">
                                <h3>Pay-Per-Click</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.</p>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>Super Responsive</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>High Security</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>Optimal Choice</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="digital-experience-image">
                                <img src="assets/img/digital-experience/digital-experience-2.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="digital-experience-content">
                                <h3>Online Marketing</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.</p>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>Super Responsive</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>High Security</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>Optimal Choice</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="digital-experience-image">
                                <img src="assets/img/digital-experience/digital-experience-3.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="digital-experience-content">
                                <h3>Email Marketing</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.</p>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>Super Responsive</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>High Security</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>Optimal Choice</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="digital-experience-image">
                                <img src="assets/img/digital-experience/digital-experience-4.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="digital-experience-content">
                                <h3>Social Marketing</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.</p>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>Super Responsive</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>High Security</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>Optimal Choice</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="digital-experience-image">
                                <img src="assets/img/digital-experience/digital-experience-5.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="digital-experience-content">
                                <h3>Digital Marketing</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.</p>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>Super Responsive</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>High Security</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>Optimal Choice</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="digital-experience-image">
                                <img src="assets/img/digital-experience/digital-experience-6.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Digital Experience -->

<!-- Fun Facts -->
<section class="fun-facts-area pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact-box">
                    <div class="icon">
                        <i class="flaticon-checked"></i>
                    </div>
                    <h3><span class="odometer" data-count="950">00</span></h3>
                    <p>Completed Project</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact-box">
                    <div class="icon">
                        <i class="flaticon-happy"></i>
                    </div>
                    <h3><span class="odometer" data-count="850">00</span></h3>
                    <p>Happy Clients</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact-box">
                    <div class="icon">
                        <i class="flaticon-technical-support"></i>
                    </div>
                    <h3><span class="odometer" data-count="550">00</span></h3>
                    <p>Multi Service</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact-box">
                    <div class="icon">
                        <i class="flaticon-trophy"></i>
                    </div>
                    <h3><span class="odometer" data-count="750">00</span></h3>
                    <p>Winning Awards</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Fun Facts -->

<!-- Pricing -->
<section class="pricing-area pb-70">
    <div class="container">
        <div class="section-title">
            <span>Pricing Plans</span>
            <h2>The Best Solutions for Our Clients</h2>
            <div class="bar"></div>
        </div>

        <div class="tab pricing-tab">
            <ul class="tabs">
                <li><a href="#">Monthly</a></li>
                <li><a href="#">Yearly</a></li>
            </ul>

            <div class="tab_content">
                <div class="tabs_item">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-pricing-box top-1">
                                <div class="pricing-header">
                                    <h3>Basic Plan</h3>
                                </div>
    
                                <div class="price">
                                    $24
                                </div>
    
                                <ul class="pricing-features">
                                    <li><i class="flaticon-check-mark"></i> SEO Audits</li>
                                    <li><i class="flaticon-check-mark"></i> SEO Management</li>
                                    <li><i class="flaticon-check-mark"></i> SEO Copywriting</li>
                                    <li><i class="flaticon-check-mark"></i> Link Building</li>
                                    <li><i class="flaticon-check-mark"></i> Site Migration</li>
                                    <li><i class="flaticon-check-mark"></i> Video Camplaigns</li>
                                    <li><i class="flaticon-check-mark"></i> Unlimited SEO Keywords</li>
                                </ul>
    
                                <div class="pricing-btn">
                                    <a routerLink="/" class="default-btn">Get Started</a>
                                </div>
    
                                <div class="pricing-shape"><img src="assets/img/pricing-shape.png" alt="image"></div>
                            </div>
                        </div>
    
                        <div class="col-lg-4 col-md-6">
                            <div class="single-pricing-box">
                                <div class="pricing-header">
                                    <h3>Standard Plan</h3>
                                </div>
    
                                <div class="price">
                                    $59
                                </div>
    
                                <ul class="pricing-features">
                                    <li><i class="flaticon-check-mark"></i> SEO Audits</li>
                                    <li><i class="flaticon-check-mark"></i> SEO Management</li>
                                    <li><i class="flaticon-check-mark"></i> SEO Copywriting</li>
                                    <li><i class="flaticon-check-mark"></i> Link Building</li>
                                    <li><i class="flaticon-check-mark"></i> Site Migration</li>
                                    <li><i class="flaticon-check-mark"></i> Video Camplaigns</li>
                                    <li><i class="flaticon-check-mark"></i> Unlimited SEO Keywords</li>
                                </ul>
    
                                <div class="pricing-btn">
                                    <a routerLink="/" class="default-btn">Get Started</a>
                                </div>
    
                                <div class="pricing-shape"><img src="assets/img/pricing-shape.png" alt="image"></div>
                            </div>
                        </div>
    
                        <div class="col-lg-4 col-md-6">
                            <div class="single-pricing-box top-2">
                                <div class="pricing-header">
                                    <h3>Premium Plan</h3>
                                </div>
    
                                <div class="price">
                                    $89
                                </div>
    
                                <ul class="pricing-features">
                                    <li><i class="flaticon-check-mark"></i> SEO Audits</li>
                                    <li><i class="flaticon-check-mark"></i> SEO Management</li>
                                    <li><i class="flaticon-check-mark"></i> SEO Copywriting</li>
                                    <li><i class="flaticon-check-mark"></i> Link Building</li>
                                    <li><i class="flaticon-check-mark"></i> Site Migration</li>
                                    <li><i class="flaticon-check-mark"></i> Video Camplaigns</li>
                                    <li><i class="flaticon-check-mark"></i> Unlimited SEO Keywords</li>
                                </ul>
    
                                <div class="pricing-btn">
                                    <a routerLink="/" class="default-btn">Get Started</a>
                                </div>
    
                                <div class="pricing-shape"><img src="assets/img/pricing-shape.png" alt="image"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-pricing-box top-1">
                                <div class="pricing-header">
                                    <h3>Basic Plan</h3>
                                </div>
    
                                <div class="price">
                                    $224
                                </div>
    
                                <ul class="pricing-features">
                                    <li><i class="flaticon-check-mark"></i> SEO Audits</li>
                                    <li><i class="flaticon-check-mark"></i> SEO Management</li>
                                    <li><i class="flaticon-check-mark"></i> SEO Copywriting</li>
                                    <li><i class="flaticon-check-mark"></i> Link Building</li>
                                    <li><i class="flaticon-check-mark"></i> Site Migration</li>
                                    <li><i class="flaticon-check-mark"></i> Video Camplaigns</li>
                                    <li><i class="flaticon-check-mark"></i> Unlimited SEO Keywords</li>
                                </ul>
    
                                <div class="pricing-btn">
                                    <a routerLink="/" class="default-btn">Get Started</a>
                                </div>
    
                                <div class="pricing-shape"><img src="assets/img/pricing-shape.png" alt="image"></div>
                            </div>
                        </div>
    
                        <div class="col-lg-4 col-md-6">
                            <div class="single-pricing-box">
                                <div class="pricing-header">
                                    <h3>Standard Plan</h3>
                                </div>
    
                                <div class="price">
                                    $259
                                </div>
    
                                <ul class="pricing-features">
                                    <li><i class="flaticon-check-mark"></i> SEO Audits</li>
                                    <li><i class="flaticon-check-mark"></i> SEO Management</li>
                                    <li><i class="flaticon-check-mark"></i> SEO Copywriting</li>
                                    <li><i class="flaticon-check-mark"></i> Link Building</li>
                                    <li><i class="flaticon-check-mark"></i> Site Migration</li>
                                    <li><i class="flaticon-check-mark"></i> Video Camplaigns</li>
                                    <li><i class="flaticon-check-mark"></i> Unlimited SEO Keywords</li>
                                </ul>
    
                                <div class="pricing-btn">
                                    <a routerLink="/" class="default-btn">Get Started</a>
                                </div>
    
                                <div class="pricing-shape"><img src="assets/img/pricing-shape.png" alt="image"></div>
                            </div>
                        </div>
    
                        <div class="col-lg-4 col-md-6">
                            <div class="single-pricing-box top-2">
                                <div class="pricing-header">
                                    <h3>Premium Plan</h3>
                                </div>
    
                                <div class="price">
                                    $289
                                </div>
    
                                <ul class="pricing-features">
                                    <li><i class="flaticon-check-mark"></i> SEO Audits</li>
                                    <li><i class="flaticon-check-mark"></i> SEO Management</li>
                                    <li><i class="flaticon-check-mark"></i> SEO Copywriting</li>
                                    <li><i class="flaticon-check-mark"></i> Link Building</li>
                                    <li><i class="flaticon-check-mark"></i> Site Migration</li>
                                    <li><i class="flaticon-check-mark"></i> Video Camplaigns</li>
                                    <li><i class="flaticon-check-mark"></i> Unlimited SEO Keywords</li>
                                </ul>
    
                                <div class="pricing-btn">
                                    <a routerLink="/" class="default-btn">Get Started</a>
                                </div>
    
                                <div class="pricing-shape"><img src="assets/img/pricing-shape.png" alt="image"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Pricing -->

<!-- Team -->
<section class="team-area pb-70">
    <div class="container">
        <div class="section-title">
            <span>Team Member</span>
            <h2>Our Expert Team</h2>
            <div class="bar"></div>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team/team-6.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Alex Maxwel</h3>
                        <span>CEO & Founder</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team/team-7.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Justin Roberto</h3>
                        <span>Head Of Marketing</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team/team-8.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Louis Agassiz</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team/team-9.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Carl Anderson</h3>
                        <span>Android/IOS Developer</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Team -->

<!-- Testimonials -->
<section class="testimonial-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 offset-lg-6">
                <div class="testimonial-content">
                    <span>Testimonials</span>
                    <h2>Our Client’s Review</h2>
                    <div class="bar"></div>
                </div>
            </div>
        </div>

        <div class="testimonial-slider owl-carousel owl-theme">
            <div class="testimonial-item">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="testimonial-image"></div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="testimonial-inner-content">
                            <div class="icon">
                                <i class="flaticon-left-quotes-sign"></i>
                            </div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida Risus  maecenas accumsan noniice Lorem Ipsum.</p>
                            <div class="info-text">
                                <h4>Morris Jacket</h4>
                                <span>Web Developer</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="testimonial-item">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="testimonial-image image-two"></div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="testimonial-inner-content">
                            <div class="icon">
                                <i class="flaticon-left-quotes-sign"></i>
                            </div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida Risus  maecenas accumsan noniice Lorem Ipsum.</p>
                            <div class="info-text">
                                <h4>Mahindra jhon</h4>
                                <span>App Developer</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="testimonial-item">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="testimonial-image image-three"></div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="testimonial-inner-content">
                            <div class="icon">
                                <i class="flaticon-left-quotes-sign"></i>
                            </div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida Risus  maecenas accumsan noniice Lorem Ipsum.</p>
                            <div class="info-text">
                                <h4>Lee Munroe</h4>
                                <span>Web Designer</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Testimonials -->

<!-- Overview -->
<section class="overview-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="overview-content">
                    <span>What We Doing Best</span>
                    <h3>Marketing Goals Achieved with Develop</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <div class="overview-btn">
                        <a routerLink="/" class="default-btn">Learn More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="overview-image">
                    <img src="assets/img/overview.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Overview -->

<!-- Blog -->
<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Blog</span>
            <h2>Latest News From Blog</h2>
            <div class="bar"></div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <div class="image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/blog-1.jpg" alt="image">
                        </a>
                    </div>
                    <div class="content">
                        <span>20 March, 2023</span>
                        <h3><a routerLink="/blog-details">7 Great Tips For Earn More Money From Digital Industry</a></h3>
                        <a routerLink="/blog-details" class="blog-btn">Read More <i class='bx bx-chevrons-right'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <div class="image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/blog-2.jpg" alt="image">
                        </a>
                    </div>
                    <div class="content">
                        <span>25 March, 2023</span>
                        <h3><a routerLink="/blog-details">How To Boost Your Digital Marketing Agency</a></h3>
                        <a routerLink="/blog-details" class="blog-btn">Read More <i class='bx bx-chevrons-right'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <div class="image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/blog-3.jpg" alt="image">
                        </a>
                    </div>
                    <div class="content">
                        <span>28 March, 2023</span>
                        <h3><a routerLink="/blog-details">The Billionaire Guide On Design That will Get You Rich</a></h3>
                        <a routerLink="/blog-details" class="blog-btn">Read More <i class='bx bx-chevrons-right'></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape1"><img src="assets/img/default-shape/default-shape1.png" alt="image"></div>
        <div class="shape2"><img src="assets/img/default-shape/default-shape2.png" alt="image"></div>
        <div class="shape3"><img src="assets/img/default-shape/default-shape3.png" alt="image"></div>
        <div class="shape4"><img src="assets/img/default-shape/default-shape4.png" alt="image"></div>
    </div>
</section>
<!-- End Blog -->

<!-- Subscribe -->
<section class="subscribe-area ptb-100">
    <div class="container">
        <div class="subscribe-content">
            <span>Get Started Instantly!</span>
            <h2>Get Only New Update from this Newsletter</h2>
            <form class="newsletter-form" data-toggle="validator">
                <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
                <button type="submit">Subscribe</button>
            </form>
        </div>
    </div>
</section>
<!-- End Subscribe -->

<!-- Partner -->
<div class="partner-area ptb-100">
    <div class="container">
        <div class="partner-slider owl-carousel owl-theme">
            <div class="partner-item">
                <img src="assets/img/partner/partner-1.png" alt="image">
            </div>

            <div class="partner-item">
                <img src="assets/img/partner/partner-2.png" alt="image">
            </div>

            <div class="partner-item">
                <img src="assets/img/partner/partner-3.png" alt="image">
            </div>

            <div class="partner-item">
                <img src="assets/img/partner/partner-4.png" alt="image">
            </div>

            <div class="partner-item">
                <img src="assets/img/partner/partner-5.png" alt="image">
            </div>

            <div class="partner-item">
                <img src="assets/img/partner/partner-6.png" alt="image">
            </div>
        </div>
    </div>
</div>
<!-- End Partner -->