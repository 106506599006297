import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-four',
  templateUrl: './home-four.component.html',
  styleUrls: ['./home-four.component.scss']
})
export class HomeFourComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {}

  // Click event handler function
  scrollToDiv(target:HTMLElement){
    
    // Scroll to the target element smoothly
    target.scrollIntoView();
  }


  handleClick(value: string) {
    var url = '/services-details';
    this.router.navigateByUrl(url).then(() => {
            const element = document.getElementById(value);
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    });
  }

  serviceClick(value: string) {
    var url = '/contact-1';
    this.router.navigateByUrl(url).then(() => {
            const element = document.getElementById(value);
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    });
  }

  sample() {
    var url = '/services-details';
    this.router.navigateByUrl(url).then(() => {
      // Wait for a short delay to ensure the DOM is fully loaded
      setTimeout(() => {
        const element = document.getElementById('mortgage');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 0); // Adjust the delay as needed
    });
 
 
  }
 
  samplecar() {
    var url = '/services-details';
    this.router.navigateByUrl(url).then(() => {
      // Wait for a short delay to ensure the DOM is fully loaded
      setTimeout(() => {
        const element = document.getElementById('car');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 0); // Adjust the delay as needed
    });
 
 
  }
 
  samplehome() {
    var url = '/services-details';
    this.router.navigateByUrl(url).then(() => {
      // Wait for a short delay to ensure the DOM is fully loaded
      setTimeout(() => {
        const element = document.getElementById('home');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 0); // Adjust the delay as needed
    });
 
 
  }
 
  samplesme() {
    var url = '/services-details';
    this.router.navigateByUrl(url).then(() => {
      // Wait for a short delay to ensure the DOM is fully loaded
      setTimeout(() => {
        const element = document.getElementById('sme');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 0); // Adjust the delay as needed
    });
 
 
  }
 
  samplebusiness(){
    var url = '/services-details';
    this.router.navigateByUrl(url).then(() => {
      // Wait for a short delay to ensure the DOM is fully loaded
      setTimeout(() => {
        const element = document.getElementById('business');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 0); // Adjust the delay as needed
    });
  }
 
  samplelife(){
    var url = '/services-details';
    this.router.navigateByUrl(url).then(() => {
      // Wait for a short delay to ensure the DOM is fully loaded
      setTimeout(() => {
        const element = document.getElementById('life');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 0); // Adjust the delay as needed
    });
  }
 
 
  samplegeneral(){
    var url = '/services-details';
    this.router.navigateByUrl(url).then(() => {
      // Wait for a short delay to ensure the DOM is fully loaded
      setTimeout(() => {
        const element = document.getElementById('general');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 0); // Adjust the delay as needed
    });
  }
}
