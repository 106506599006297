

<!-- Navbar -->
<div class="navbar-area">
    <!-- Mobile Top Menu -->
    <div class="d-flex justify-content-end ">
        <div class="logo" class="hidden-custom-style">
            <a routerLink="/home-four"><img src="assets/img/vforufinancialservices.png" alt="image"></a>
        </div>
        <i class="fa-solid fa-bars fs-3 hidden-custom-style pe-3" (click)="toggleMenu()"></i>
    </div>
    

    <div class="mobile-top-menu hidden-custom-style" *ngIf="isMenuVisible" (click)="toggleMenu()">
      <a routerLink="/home-four">Home</a>
      <a routerLink="/services-details">Services</a>
      <a routerLink="/testimonials">EMI Calculator</a>
      <a routerLink="/about-2">About us</a>
      <a routerLink="/career">Career</a>
      <a routerLink="/contact-1">Contact us</a>
    </div>
    
  
    <!-- Desktop Navbar -->
    <div  class="main-navbar">
      <div class="container">
        <nav class="navbar navbar-expand-md navbar-light">
          <a class="navbar-brand" routerLink="/"><img src="assets/img/vforufinancialservices.png" alt="image"></a>
  
      
  
          <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a routerLink="/home-four" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home</a>
                </li>

                <li class="nav-item">
                    <a routerLink="/services-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services</a>
                </li>

                <li class="nav-item">
                    <a routerLink="/testimonials" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">EMI Calculator</a>
                 </li>

                <li class="nav-item">
                    <a routerLink="/about-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About us</a>
                 </li> 

                 <li class="nav-item">
                    <a routerLink="/career" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Career</a>
                 </li> 



                <li class="nav-item">
                    <a routerLink="/contact-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact us</a>
                </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </div>
  <!-- End Navbar -->
