<div class="d-flex flex-row flex-wrap">
    <div class="col-lg-8 col-12">
        <div class="tab products-details-tab  mobile">


            <div class="tab_content">
                <div class="tabs_item">
                    <div class="products-details-tab-content">
                        <h3>EMI Calculator</h3>
                        <div class="row justify-content-center" style="font-size: 19px;">
                            <div class="col-8">
                                <label id="example-name-label" class="example-name-label float-start">Loan
                                    Amount</label>
                            </div>
                            <div class="col-4">
                                <div class="input-group float-end editbox">
                                    <input type="text" class="form-control float-end text-center" [(ngModel)]="Value  "
                                        (input)="updateSlider('Value')" (keypress)="allowOnlyNumbers($event)" (blur)="validateValue()" maxlength="8"
                                        pattern="[0-9]{8}" style="width: 40%;">
                                    <span class="input-group-text">₹</span>
                                </div>
                            </div>

                            <mat-slider class="example-margin" min="500000" max="20000000" step="1" [value]="Value"
                                style="width: 636px;" (input)="updateInput('Value', $event)">
                                <input matSliderThumb [(ngModel)]="Value">
                            </mat-slider>
                        </div>

                        <div class="row justify-content-center" style="font-size: 19px;">
                            <div class="col-8">
                                <label id="example-name-label" class="example-name-label float-start">Interest
                                    Rate</label>
                            </div>
                            <div class="col-4">
                                <div class="input-group float-end editbox">
                                    <button class="btn btn-outline-secondary" style="border-color: #cfd9e3;" (click)="decrementValue()">-</button>
                                    <input type="text" class="form-control float-end text-center"
                                        [(ngModel)]="ValueRate" (input)="updateSlider('ValueRate')"
                                        (keypress)="allowOnlyNumbers($event)" (blur)="validateRate()" maxlength="5" pattern="[0-9]{6,}" style="width: 30%;">
                                    <button class="btn btn-outline-secondary" style="border-color: #cfd9e3;" (click)="incrementValue()">+</button>
                                    <span class="input-group-text">%</span>
                                </div>
                                
                                
                            </div>
                            <mat-slider class="example-margin" min="1" max="30" step="0.01" [value]="ValueRate"
                                style="width: 636px;" (input)="updateInput('ValueRate', $event)">
                                <input matSliderThumb [(ngModel)]="ValueRate">
                            </mat-slider>
                        </div>




                        <div class="row justify-content-center" style="font-size: 19px;">
                            <div class="col-8">
                                <label id="example-name-label" class="example-name-label float-start">Tenure</label>
                            </div>
                            <div class="col-4">
                                <div class="input-group float-end editbox">
                                    <button class="btn btn-outline-secondary" style="border-color: #cfd9e3;"
                                        (click)="decrementYear()">-</button>
                                    <input type="text" class="form-control text-center" [(ngModel)]="ValueTenure"
                                        (input)="updateSlider('ValueTenure')"(keypress)="allowOnlyNumbers($event)" (blur)="validateTenure()" maxlength="2"
                                        pattern="[0-9]{1,2}">
                                    <button class="btn btn-outline-secondary" style="border-color: #cfd9e3;"
                                        (click)="incrementYear()">+</button>
                                    <span class="input-group-text">Yr</span>
                                </div>
                            </div>

                            <mat-slider class="example-margin" min="1" max="30" step="1" [value]="ValueTenure"
                                style="width: 636px;" (input)="updateInput('ValueTenure', $event)">
                                <input matSliderThumb [(ngModel)]="ValueTenure">
                            </mat-slider>
                        </div>

                        <div class="others-options d-flex align-items-center justify-content-center">
                            <div class="option-item">
                                <a (click)="calculateEMI(Value, ValueRate, ValueTenure)" class="default-btn"
                                    style="cursor: pointer;">Calculate
                                    EMI</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-4 col-md-12 mt-5 mb-auto d-flex justify-content-center">
        <aside class="widget-area text-center" *ngIf="sample">
            <div class="widget widget_popular_products ms-4 totalpayment ">
                <h3 class="widget-title">Total Payment</h3>

                <div class="row">
                    <div class="col-6">
                        Principal:
                    </div>
                    <div class="col-6">
                        <span *ngIf="Value">{{Value | currency:'INR':'symbol':'1.0-0'}}</span>
                    </div>
                </div><br>

                <div class="row">
                    <div class="col-6">
                        ROI:
                    </div>
                    <div class="col-6">
                        <span *ngIf="ValueRate">{{ValueRate}} %</span>
                    </div>
                </div><br>

                <div class="row">
                    <div class="col-6">
                        Tenure:
                    </div>
                    <div class="col-6">
                        <span *ngIf="ValueTenure">{{ValueTenure}} Yr</span>
                    </div>
                </div><br>

                <div class="row">
                    <div class="col-6">
                        EMI:
                    </div>
                    <div class="col-6" *ngIf="emi">
                        <h6>
                            <span *ngIf="emi" style="color: rgb(255, 0, 0);">{{ emi | currency:'INR':'symbol':'1.2-2' }}
                            </span>
                        </h6>
                    </div>
                </div><br>
                <a (click)="scrollToDiv(schedule)" class="text-center text-primary"><b>View Schedule</b></a>

            </div>
        </aside>
    </div>




</div><br>







<section class="contact-area pb-100" #schedule>
    <div class="container" *ngIf="emiSchedule.length > 0">
        <div class="section-title">

            <h2>Repayment Schedule</h2>
            <div class="bar"></div>
        </div>
        <div class="contact-form">
            <button type="button" class="default-btn downloadpdf " (click)="downloadpdf()">Download</button><br><br>
            <div style="max-height: 300px; overflow: auto;">
                <table style="border-collapse: collapse; width: 100%; border-radius: 2px;">
                    <thead>
                        <tr class="table text-center text-light border" style="border-radius: 2px;">
                            <th scope="col" style="padding: 8px;">Month</th>
                            <th scope="col" style="padding: 8px;">EMI</th>
                            <th scope="col" style="padding: 8px;">Interest</th>
                            <th scope="col" style="padding: 8px;">Outstanding Balance</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let emi_list of emiSchedule; let i = index"
                            style="border-radius: 2px; border: 1px solid #ccc;">
                            <td class="text-center" style="padding: 8px; border: 1px solid #ccc;">{{ i + 1 }}</td>
                            <td class="text-center" style="padding: 8px; border: 1px solid #ccc;">{{ emi_list.emi |
                                currency:
                                'INR' }}</td>
                            <td class="text-center" style="padding: 8px; border: 1px solid #ccc;">{{ emi_list.interest |
                                currency: 'INR' }}</td>
                            <td class="text-center" style="padding: 8px; border: 1px solid #ccc;">{{ emi_list.balance |
                                currency: 'INR' }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>



        </div>
    </div>
</section>