import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-services-details',
  templateUrl: './services-details.component.html',
  styleUrls: ['./services-details.component.scss']
})
export class ServicesDetailsComponent implements AfterViewInit {
  constructor(private router: Router) { }

  

  @ViewChild('home') homeElement: ElementRef;
 
     ngAfterViewInit(): void {
       if (this.homeElement) {
         this.homeElement.nativeElement.scrollIntoView({ behavior: 'smooth' });
       }
     }

     serviceClick(value: string) {
      var url = '/contact-1';
      this.router.navigateByUrl(url).then(() => {
              const element = document.getElementById(value);
              element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      });
    }

}
