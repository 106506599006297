<!-- Page Banner -->
<div class="page-banner-area item-bg3">
    <div class="container">
        <div class="page-banner-content">
            <h2>Shop List</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Shop List</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Banner -->

<!-- Products -->
<section class="product-area pt-100 pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="rainz-grid-sorting row align-items-center">
                    <div class="col-lg-6 col-md-6 result-count">
                        <p>We found <span class="count">10</span> products available for you</p>
                    </div>

                    <div class="col-lg-6 col-md-6 ordering">
                        <div class="select-box">
                            <label>Sort By:</label>
                            <select>
                                <option>Default</option>
                                <option>Popularity</option>
                                <option>Latest</option>
                                <option>Price: low to high</option>
                                <option>Price: high to low</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="single-products-box">
                            <div class="products-image">
                                <a routerLink="/products-details">
                                    <img src="assets/img/shop/shop1.jpg" alt="image">
                                </a>
                            </div>
                            <div class="products-content">
                                <h3><a routerLink="/products-details">Note Book Mockup</a></h3>
                                <div class="price">
                                    <span class="old-price">$125</span>
                                    <span class="new-price">$120</span>
                                </div>
                                <div class="star-rating">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <a routerLink="/cart" class="add-to-cart">Add to Cart</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-products-box">
                            <div class="products-image">
                                <a routerLink="/products-details">
                                    <img src="assets/img/shop/shop2.jpg" alt="image">
                                </a>
                            </div>
                            <div class="products-content">
                                <h3><a routerLink="/products-details">Motivational Book Cover</a></h3>
                                <div class="price">
                                    <span class="old-price">$225</span>
                                    <span class="new-price">$220</span>
                                </div>
                                <div class="star-rating">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <a routerLink="/cart" class="add-to-cart">Add to Cart</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-products-box">
                            <div class="products-image">
                                <a routerLink="/products-details">
                                    <img src="assets/img/shop/shop3.jpg" alt="image">
                                </a>
                            </div>
                            <div class="products-content">
                                <h3><a routerLink="/products-details">Book Cover Softcover</a></h3>
                                <div class="price">
                                    <span class="old-price">$265</span>
                                    <span class="new-price">$270</span>
                                </div>
                                <div class="star-rating">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <a routerLink="/cart" class="add-to-cart">Add to Cart</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-products-box">
                            <div class="products-image">
                                <a routerLink="/products-details">
                                    <img src="assets/img/shop/shop4.jpg" alt="image">
                                </a>
                            </div>
                            <div class="products-content">
                                <h3><a routerLink="/products-details">Stop and Take a Second</a></h3>
                                <div class="price">
                                    <span class="old-price">$125</span>
                                    <span class="new-price">$120</span>
                                </div>
                                <div class="star-rating">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <a routerLink="/cart" class="add-to-cart">Add to Cart</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-products-box">
                            <div class="products-image">
                                <a routerLink="/products-details">
                                    <img src="assets/img/shop/shop5.jpg" alt="image">
                                </a>
                            </div>
                            <div class="products-content">
                                <h3><a routerLink="/products-details">Real Life Fairytale</a></h3>
                                <div class="price">
                                    <span class="old-price">$225</span>
                                    <span class="new-price">$220</span>
                                </div>
                                <div class="star-rating">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <a routerLink="/cart" class="add-to-cart">Add to Cart</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-products-box">
                            <div class="products-image">
                                <a routerLink="/products-details">
                                    <img src="assets/img/shop/shop6.jpg" alt="image">
                                </a>
                            </div>
                            <div class="products-content">
                                <h3><a routerLink="/products-details">Running From Me</a></h3>
                                <div class="price">
                                    <span class="old-price">$265</span>
                                    <span class="new-price">$270</span>
                                </div>
                                <div class="star-rating">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <a routerLink="/cart" class="add-to-cart">Add to Cart</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="pagination-area">
                            <a routerLink="/shop-2" class="prev page-numbers"><i class="flaticon-left-arrow"></i></a>
                            <a routerLink="/shop-2" class="page-numbers">1</a>
                            <span class="page-numbers current" aria-current="page">2</span>
                            <a routerLink="/shop-2" class="page-numbers">3</a>
                            <a routerLink="/shop-2" class="page-numbers">4</a>
                            <a routerLink="/shop-2" class="next page-numbers"><i class="flaticon-next"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                    <section class="widget widget_search">
                        <h3 class="widget-title">Search</h3>

                        <form class="search-form">
                            <label>
                                <span class="screen-reader-text">Search for:</span>
                                <input type="search" class="search-field" placeholder="Search...">
                            </label>
                            <button type="submit"><i class="flaticon-loupe"></i></button>
                        </form>
                    </section>

                    <section class="widget price_list_widget">
                        <h3 class="widget-title">Price</h3>

                        <form>
                            <div class="form-group mb-3">
                                <input type="text" class="form-control" placeholder="$10">
                            </div>
                        </form>
                    </section>

                    <section class="widget widget_popular_products">
                        <h3 class="widget-title">Popular Products</h3>

                        <article class="item">
                            <a routerLink="/products-details" class="thumb">
                                <span class="fullimage cover bg1" role="img"></span>
                            </a>
                            <div class="info">
                                <span>$49.00</span>
                                <h4 class="title usmall"><a routerLink="/products-details">Random Romance Novel Title Generator</a></h4>
                                <div class="rating">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                            </div>
                            <div class="clear"></div>
                        </article>

                        <article class="item">
                            <a routerLink="/products-details" class="thumb">
                                <span class="fullimage cover bg2" role="img"></span>
                            </a>
                            <div class="info">
                                <span>$59.00</span>
                                <h4 class="title usmall"><a routerLink="/products-details">Writing Exercises Story Title Ideas</a></h4>
                                <div class="rating">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                            </div>
                            <div class="clear"></div>
                        </article>

                        <article class="item">
                            <a routerLink="/products-details" class="thumb">
                                <span class="fullimage cover bg3" role="img"></span>
                            </a>
                            <div class="info">
                                <span>$69.00</span>
                                <h4 class="title usmall"><a routerLink="/products-details">Amaze Story Kitt Net's Book Ideas</a></h4>
                                <div class="rating">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                            </div>
                            <div class="clear"></div>
                        </article>
                    </section>

                    <section class="widget widget_tag_cloud">
                        <h3 class="widget-title">Popular Tags</h3>

                        <div class="tagcloud">
                            <a routerLink="/">Business <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/">Design <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/">Digital <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/">SEO <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/">Braike <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/">Fashion <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/">Software <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/">Travel <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Smart <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Marketing <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Tips <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/">Website <span class="tag-link-count"> (2)</span></a>
                        </div>
                    </section>
                </aside>
            </div>
        </div>
    </div>
</section>
<!-- End Products -->