<!-- Page Banner -->
<div class="page-banner-area contactus">
    <div class="container">
        <div class="page-banner-content">
            <h2>Contact Us</h2>
            <ul>
                <li><a routerLink="/home-four">Home</a></li>
                <li>Contact Us</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Banner -->
<section class="contact-info-area pt-100 pb-70">
    <div class="container">
<div class="row">
    <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-features">
            <div class="icon">
                <i class="flaticon-email"></i>
            </div>
            <h3>Email Here</h3>
            <p style="font-size: 19px;"><a href="mailto:info@vforuloans.com">info@vforuloans.com</a></p><br><br><br>
            <div class="shape">
                <img src="assets/img/features-shape/features-shape-1.png" alt="image">
            </div>
        </div>
    </div>

    <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-features">
            <div class="icon">
                <i class="flaticon-pin"></i>
            </div>
            <h3>Location Here</h3>
            <p style="font-size: 19px;"><a href="https://maps.app.goo.gl/orbJUkeoqhNkK6k36" target="_blank">No:42/2, 5th street, Kasi Estate,
                    Jafferkhanpet, Chennai - 83
                    (Behind Kasi theatre).
                </a></p>
            <div class="shape">
                <img src="assets/img/features-shape/features-shape-1.png" alt="image">
            </div>
        </div>
    </div>

    <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-features">
            <div class="icon">
                <i class="flaticon-call"></i>
            </div>
            <h3>Call Here</h3>
            <p style="font-size: 19px;"><a>9884323232 / 9841323232</a></p>
            <p style="font-size: 19px;"><a>04442617121</a></p><br>
            <div class="shape" >
                <img src="assets/img/features-shape/features-shape-1.png" alt="image" >
            </div>
        </div>
    </div>
</div>
</div>
</section >
<!-- Contact -->
<section class="contact-area pb-100" id="contact" >
    <div class="container">
        <div class="section-title">
            <span>Get in Touch</span>
            <h2>Ready to Get Started?</h2>
            <div class="bar"></div>
        </div>
        <div class="contact-form">
            <form [formGroup]="AddForm" id="contactForm">
                <div class="row" >
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group mb-3">
                            <label>First Name <span class="text-danger">*</span> </label>
                            <input type="text" formControlName="fname" id="fname" class="form-control" [ngModel]="fname"
                            [ngStyle]="{ 'border-color': AddForm.get('fname')?.hasError('required') && AddForm.get('fname')?.touched ? 'red' : '#eaeaea' }"
                            >
                            <div
                            *ngIf="AddForm.get('fname')?.hasError('required') && AddForm.get('fname')?.touched"
                            class="text-danger">
                            First Name is required.
                          </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group mb-3">
                            <label>Last Name <span class="text-danger">*</span></label>
                            <input type="text" formControlName="lname" name="name" id="lname" class="form-control"
                            [ngStyle]="{ 'border-color': AddForm.get('lname')?.hasError('required') && AddForm.get('lname')?.touched ? 'red' : '#eaeaea' }"
                            >
                            <div
                            *ngIf="AddForm.get('lname')?.hasError('required') && AddForm.get('lname')?.touched"
                            class="text-danger">
                            Last Name is required.
                          </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group mb-3">
                            <label>Email <span class="text-danger">*</span></label>
                            <input type="email" formControlName="email" name="email" id="email" class="form-control"
                            [ngStyle]="{ 'border-color': AddForm.get('email')?.hasError('required') && AddForm.get('email')?.touched ? 'red' : '#eaeaea' }"
                            >
                            <div
                            *ngIf="AddForm.get('email')?.hasError('required') && AddForm.get('email')?.touched"
                            class="text-danger">
                            Email is required.
                          </div>
                          <div class="text-danger" *ngIf="AddForm.get('email')?.hasError('pattern')">
                            Enter the Valid Email Id
                          </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group mb-3">
                            <label>Phone <span class="text-danger">*</span></label>
                            <input maxlength="10" type="text" formControlName="phone" name="phone_number" id="phone_number" class="form-control"
                            [ngStyle]="{ 'border-color': AddForm.get('phone')?.hasError('required') && AddForm.get('phone')?.touched ? 'red' : '#eaeaea' }"
                            >
                            <div
                            *ngIf="AddForm.get('phone')?.hasError('required') && AddForm.get('phone')?.touched"
                            class="text-danger">
                            Phone is required.
                          </div>
                          <div class="text-danger" *ngIf="AddForm.get('phone')?.hasError('pattern')">
                            Phone Number containing only numeric digits.
                          </div>
                          <div *ngIf="AddForm.get('phone').errors?.minlength" class="text-danger">
                            Mobile Number not exceed 10 numeric digits.
                          </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group mb-3" >
                            <label>Product<span class="text-danger">*</span></label><br>
                            <mat-form-field style="width: 100%;">
                                <mat-label>Choose an option</mat-label>
                                <mat-select formControlName="product" >
                                  <mat-option *ngFor="let option of options" [value]="option.label">
                                    {{ option.label }}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                              <div *ngIf="AddForm.get('product')?.hasError('required') && AddForm.get('product')?.touched" class="text-danger">
                                Product is required.
                              </div>    
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group mb-3">
                            <label>Loan<span class="text-danger">*</span></label> <br>
                            <mat-form-field style="width: 100%;">
                                <mat-label>Choose an option</mat-label>
                                <mat-select formControlName="loan">
                                  <mat-option *ngFor="let option of loanoption" [value]="option.loan">
                                    {{ option.loan }}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                              <div *ngIf="AddForm.get('loan')?.hasError('required') && AddForm.get('loan')?.touched" class="text-danger">
                                loan is required.
                              </div> 
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <button type="button" [disabled]="AddForm.invalid" class="default-btn" (click)="onadd()">Send Mail</button>
                        <p class="mt-2" style="font-size: 14px;" *ngIf="AddForm.invalid">Kindly fill all the mandatory (<span class="text-danger fw-bold">*</span>) fields  </p>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>