import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-services-style-three',
  templateUrl: './services-style-three.component.html',
  styleUrls: ['./services-style-three.component.scss']
})
export class ServicesStyleThreeComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {}

  // Click event handler function
  scrollToDiv(target:HTMLElement){
    
    // Scroll to the target element smoothly
    target.scrollIntoView();
  }
  
  sample() {
    var url = '/services-details';
    this.router.navigateByUrl(url).then(() => {
      // Wait for a short delay to ensure the DOM is fully loaded
      setTimeout(() => {
        const element = document.getElementById('details1');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 100); // Adjust the delay as needed
    });

  
  }

  handleClick(value: string) {
    var url = '/services-details';
    this.router.navigateByUrl(url).then(() => {
            const element = document.getElementById(value);
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    });
  }
  
  

  

}
