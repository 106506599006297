<!-- Page Banner -->
<div class="page-banner-area item-bg3">
    <div class="container">
        <div class="page-banner-content">
            <h2>About Us</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>About Us</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Banner -->

<!-- About -->
<section class="about-area ptb-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="about-image-warp"></div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content warp">
                    <span>About Us</span>
                    <h3>We Help Companies to Generate Leads and Increase in Sales</h3>
                    <div class="bar"></div>
                    <strong>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt labore  dolore magna aliqua.</strong>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt labore  dolore magna aliqua.</p>
                </div>
            </div>
        </div>
    </div>
</section>   
<!-- End About -->

<!-- Team -->
<section class="team-area pb-100">
    <div class="container-fluid">
        <div class="section-title">
            <span>Team Member</span>
            <h2>Our Expert Team</h2>
            <div class="bar"></div>
        </div>
        
        <div class="team-slider owl-carousel owl-theme">
            <div class="single-team">
                <div class="image">
                    <img src="assets/img/team/team-1.jpg" alt="image">
                
                    <ul class="social">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>

                    <div class="content">
                        <h3>Justin Roberto</h3>
                        <span>CEO & Founder</span>
                    </div>
                </div>
            </div>

            <div class="single-team">
                <div class="image">
                    <img src="assets/img/team/team-2.jpg" alt="image">
                
                    <ul class="social">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>

                    <div class="content">
                        <h3>Jacinda Meri</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
            </div>

            <div class="single-team">
                <div class="image">
                    <img src="assets/img/team/team-3.jpg" alt="image">
                
                    <ul class="social">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>

                    <div class="content">
                        <h3>Miraj Alex</h3>
                        <span>Chief Executive Officer</span>
                    </div>
                </div>
            </div>

            <div class="single-team">
                <div class="image">
                    <img src="assets/img/team/team-4.jpg" alt="image">
                
                    <ul class="social">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>

                    <div class="content">
                        <h3>Edward Bold</h3>
                        <span>UI/UX Designer</span>
                    </div>
                </div>
            </div>

            <div class="single-team">
                <div class="image">
                    <img src="assets/img/team/team-5.jpg" alt="image">
                
                    <ul class="social">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>

                    <div class="content">
                        <h3>Alastair Cook</h3>
                        <span>Marketing Support</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Team -->

<!-- Pricing -->
<section class="pricing-area pb-70">
    <div class="container">
        <div class="section-title">
            <span>Pricing Plans</span>
            <h2>The Best Solutions for Our Clients</h2>
            <div class="bar"></div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-pricing-box top-1">
                    <div class="pricing-header">
                        <h3>Basic Plan</h3>
                    </div>

                    <div class="price">
                        $24
                    </div>

                    <ul class="pricing-features">
                        <li><i class="flaticon-check-mark"></i> SEO Audits</li>
                        <li><i class="flaticon-check-mark"></i> SEO Management</li>
                        <li><i class="flaticon-check-mark"></i> SEO Copywriting</li>
                        <li><i class="flaticon-check-mark"></i> Link Building</li>
                        <li><i class="flaticon-check-mark"></i> Site Migration</li>
                        <li><i class="flaticon-check-mark"></i> Video Camplaigns</li>
                        <li><i class="flaticon-check-mark"></i> Unlimited SEO Keywords</li>
                    </ul>

                    <div class="pricing-btn">
                        <a routerLink="/" class="default-btn">Get Started</a>
                    </div>

                    <div class="pricing-shape"><img src="assets/img/pricing-shape.png" alt="image"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-pricing-box">
                    <div class="pricing-header">
                        <h3>Standard Plan</h3>
                    </div>

                    <div class="price">
                        $59
                    </div>

                    <ul class="pricing-features">
                        <li><i class="flaticon-check-mark"></i> SEO Audits</li>
                        <li><i class="flaticon-check-mark"></i> SEO Management</li>
                        <li><i class="flaticon-check-mark"></i> SEO Copywriting</li>
                        <li><i class="flaticon-check-mark"></i> Link Building</li>
                        <li><i class="flaticon-check-mark"></i> Site Migration</li>
                        <li><i class="flaticon-check-mark"></i> Video Camplaigns</li>
                        <li><i class="flaticon-check-mark"></i> Unlimited SEO Keywords</li>
                    </ul>

                    <div class="pricing-btn">
                        <a routerLink="/" class="default-btn">Get Started</a>
                    </div>

                    <div class="pricing-shape"><img src="assets/img/pricing-shape.png" alt="image"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-pricing-box top-2">
                    <div class="pricing-header">
                        <h3>Premium Plan</h3>
                    </div>

                    <div class="price">
                        $89
                    </div>

                    <ul class="pricing-features">
                        <li><i class="flaticon-check-mark"></i> SEO Audits</li>
                        <li><i class="flaticon-check-mark"></i> SEO Management</li>
                        <li><i class="flaticon-check-mark"></i> SEO Copywriting</li>
                        <li><i class="flaticon-check-mark"></i> Link Building</li>
                        <li><i class="flaticon-check-mark"></i> Site Migration</li>
                        <li><i class="flaticon-check-mark"></i> Video Camplaigns</li>
                        <li><i class="flaticon-check-mark"></i> Unlimited SEO Keywords</li>
                    </ul>

                    <div class="pricing-btn">
                        <a routerLink="/" class="default-btn">Get Started</a>
                    </div>

                    <div class="pricing-shape"><img src="assets/img/pricing-shape.png" alt="image"></div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Pricing -->

<!-- Testimonial -->
<section class="testimonial-area testimonial-two pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 offset-lg-6">
                <div class="testimonial-content">
                    <span>Testimonials</span>
                    <h2>Our Client’s Review</h2>
                    <div class="bar"></div>
                </div>
            </div>
        </div>

        <div class="testimonial-slider owl-carousel owl-theme">
            <div class="testimonial-item">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="testimonial-image"></div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="testimonial-inner-content">
                            <div class="icon">
                                <i class="flaticon-left-quotes-sign"></i>
                            </div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida Risus  maecenas accumsan noniice Lorem Ipsum.</p>
                            <div class="info-text">
                                <h4>Morris Jacket</h4>
                                <span>Web Developer</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="testimonial-item">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="testimonial-image image-two"></div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="testimonial-inner-content">
                            <div class="icon">
                                <i class="flaticon-left-quotes-sign"></i>
                            </div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida Risus  maecenas accumsan noniice Lorem Ipsum.</p>
                            <div class="info-text">
                                <h4>Mahindra jhon</h4>
                                <span>App Developer</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="testimonial-item">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="testimonial-image image-three"></div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="testimonial-inner-content">
                            <div class="icon">
                                <i class="flaticon-left-quotes-sign"></i>
                            </div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida Risus  maecenas accumsan noniice Lorem Ipsum.</p>
                            <div class="info-text">
                                <h4>Lee Munroe</h4>
                                <span>Web Designer</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Testimonial -->

<!-- FunFacts -->
<section class="fun-facts-area pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact-box">
                    <div class="icon">
                        <i class="flaticon-checked"></i>
                    </div>
                    <h3><span class="odometer" data-count="950">00</span></h3>
                    <p>Completed Project</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact-box">
                    <div class="icon">
                        <i class="flaticon-happy"></i>
                    </div>
                    <h3><span class="odometer" data-count="850">00</span></h3>
                    <p>Happy Clients</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact-box">
                    <div class="icon">
                        <i class="flaticon-technical-support"></i>
                    </div>
                    <h3><span class="odometer" data-count="550">00</span></h3>
                    <p>Multi Service</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact-box">
                    <div class="icon">
                        <i class="flaticon-trophy"></i>
                    </div>
                    <h3><span class="odometer" data-count="750">00</span></h3>
                    <p>Winning Awards</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End FunFacts -->

<!-- Overview -->
<section class="overview-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="overview-content">
                    <span>What We Doing Best</span>
                    <h3>Marketing Goals Achieved with Develop</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <div class="overview-btn">
                        <a routerLink="/" class="default-btn">Learn More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="overview-image">
                    <img src="assets/img/overview.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Overview -->

<!-- Subscribe -->
<section class="subscribe-area ptb-100">
    <div class="container">
        <div class="subscribe-content">
            <span>Get Started Instantly!</span>
            <h2>Get Only New Update from this Newsletter</h2>
            <form class="newsletter-form">
                <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
                <button type="submit">Subscribe</button>
            </form>
        </div>
    </div>
</section>
<!-- End Subscribe -->

<!-- Partner -->
<div class="partner-area ptb-100">
    <div class="container">
        <div class="partner-slider owl-carousel owl-theme">
            <div class="partner-item">
                <img src="assets/img/partner/partner-1.png" alt="image">
            </div>

            <div class="partner-item">
                <img src="assets/img/partner/partner-2.png" alt="image">
            </div>

            <div class="partner-item">
                <img src="assets/img/partner/partner-3.png" alt="image">
            </div>

            <div class="partner-item">
                <img src="assets/img/partner/partner-4.png" alt="image">
            </div>

            <div class="partner-item">
                <img src="assets/img/partner/partner-5.png" alt="image">
            </div>

            <div class="partner-item">
                <img src="assets/img/partner/partner-6.png" alt="image">
            </div>
        </div>
    </div>
</div>
<!-- End Partner -->