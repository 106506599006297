<!-- Footer -->
<div class="footer-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/home-four"><img src="assets/img/vforufinancialservices.png" alt="image"></a>
                    </div>
                    <p>Experience exceptional loan services and financial expertise with <b>V FOR U Groups</b> - A Trusted Leader in the Chennai market since 2000.</p>
                    <!-- <ul class="social">
                        <li><a href="#" class="facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="twitter" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="pinterest" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" class="linkedin" target="_blank"><i class='bx bxl-instagram-alt'></i></a></li>
                    </ul> -->
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Important Links</h3>

                    <ul class="quick-links">
                        <li><a routerLink="/home-four">Home</a></li>
                        <li><a routerLink="/services-details">Services</a></li>
                        <li><a routerLink="/testimonials">EMI Calculator</a></li>
                        <li><a routerLink="/about-2">About us</a></li>
                        <li><a routerLink="/career">Career</a></li>
                        <li><a routerLink="/contact-1">Contact us</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Services</h3>

                    <ul class="quick-links">
                        <li><a routerLink="/services-details" (click)="handleClick('mortgage')">Mortgage Loans</a></li>
                        <li><a routerLink="/services-details" (click)="handleClick('car')">Car Loans</a></li>
                        <li><a routerLink="/services-details" (click)="handleClick('home')">Home Loans</a></li>
                        <li><a routerLink="/services-details" (click)="handleClick('sme')">SME Loans</a></li>
                        <li><a routerLink="/services-details" (click)="handleClick('business')">Business Loans</a></li>
                        <li><a routerLink="/services-details" (click)="handleClick('life')">Life Insurance</a></li>
                        <li><a routerLink="/services-details" (click)="handleClick('general')"> General Insurance</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Contact</h3>

                    <ul class="footer-contact-info">
                        <li>
                            <i class="flaticon-call"></i>
                            <span>Phone</span>
                            <a>9884323232 </a> /
                            <a>9841323232 </a> /
                            <a>04442617121 </a>
                        </li>
                        <li>
                            <i class="flaticon-email-1"></i>
                            <span>Email</span>
                            <a href="mailto:info@vforuloans.com">info@vforuloans.com</a>
                        </li>
                        <li>
                            <i class="flaticon-pin"></i>
                            <span>Address</span>
                            <a href="https://maps.app.goo.gl/orbJUkeoqhNkK6k36" target="_blank">No:42/2, 5th street, Kasi Estate,
                                Jafferkhanpet, Chennai - 83
                                (Behind Kasi theatre).
                                </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Footer -->

<!-- Copyright -->
<!-- <div class="copyright-area">
    <div class="container">
        <div class="copyright-area-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p>© Rainz is Proudly Owned by <a href="https://envytheme.com/" target="_blank">EnvyTheme</a></p>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/terms-of-service">Terms of Service</a></li>
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Copyright -->
<div class="text-center" style="background-color: rgb(234, 178, 178); color: black;">
    ©  Copyright 2023 V for U Financial Services.All right reserved.
  </div>
<!-- Go Top -->
<div class="go-top active">
    <i class='bx bx-chevron-up'></i>
</div>
<!-- End Go Top -->