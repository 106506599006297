<!-- Page Banner -->
<div class="page-banner-area item-bg4">
    <div class="container">
        <div class="page-banner-content">
            <h2>Login</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Login</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Banner -->

<!-- Login -->
<section class="login-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="login-image">
                    <img src="assets/img/login-form.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="login-form">
                    <h2>Login</h2>

                    <form>
                        <div class="form-group mb-3">
                            <label>Username or email</label>
                            <input type="text" class="form-control" placeholder="Username or email">
                        </div>

                        <div class="form-group mb-3">
                            <label>Password</label>
                            <input type="password" class="form-control" placeholder="Password">
                        </div>

                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="checkme">
                                    <label class="form-check-label" for="checkme">Remember me</label>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-6 lost-your-password">
                                <a routerLink="/forgot-password" class="lost-your-password">Lost your password?</a>
                            </div>
                        </div>

                        <button type="submit" class="default-btn">Login</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Login -->