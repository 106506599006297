
<!-- Page Banner -->
<div class="page-banner-area career">
    <div class="container">
        <div class="page-banner-content" >
            <h2>Career</h2>
            <ul>
                <li><a routerLink="/home-four">Home</a></li>
                <li>Career</li>
            </ul>
        </div>
    </div>
</div>

<section class="contact-area pb-100">
    <div class="container">
        <div class="section-title">
            <!-- <span>Get in Touch</span> -->
            <h2>Build Your Career Here</h2>

            <h5>Field Executives/ Team Leaders/ Tele-Callers</h5>
            <div class="bar"></div>
            <br>
            <p>We are recruiting candidates for the above mentioned Job Titles. Kindly send us your profile by filling the form.</p>
        </div>

        <div class="contact-form">
            <form [formGroup]="AddForm" id="contactForm">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group mb-3">
                            <label>First Name <span class="text-danger">*</span> </label>
                            <input type="text" formControlName="fname" id="fname" class="form-control" 
                            [ngStyle]="{ 'border-color': AddForm.get('fname')?.hasError('required') && AddForm.get('fname')?.touched ? 'red' : '#eaeaea' }"
                            >
                            <div
                            *ngIf="AddForm.get('fname')?.hasError('required') && AddForm.get('fname')?.touched"
                            class="text-danger">
                            First Name is required.
                          </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group mb-3">
                            <label>Last Name <span class="text-danger">*</span></label>
                            <input type="text" formControlName="lname" name="name" id="lname" class="form-control"
                            [ngStyle]="{ 'border-color': AddForm.get('lname')?.hasError('required') && AddForm.get('lname')?.touched ? 'red' : '#eaeaea' }"
                            >
                            <div
                            *ngIf="AddForm.get('lname')?.hasError('required') && AddForm.get('lname')?.touched"
                            class="text-danger">
                            Last Name is required.
                          </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group mb-3">
                            <label>Email <span class="text-danger">*</span></label>
                            <input type="email" formControlName="email" name="email" id="email" class="form-control"
                            [ngStyle]="{ 'border-color': AddForm.get('email')?.hasError('required') && AddForm.get('email')?.touched ? 'red' : '#eaeaea' }"
                            >
                            <div
                            *ngIf="AddForm.get('email')?.hasError('required') && AddForm.get('email')?.touched"
                            class="text-danger">
                            Email is required.
                          </div>

                          <div class="text-danger" *ngIf="AddForm.get('email')?.hasError('pattern')">
                            Enter the Valid Email Id
                          </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group mb-3">
                            <label>Phone <span class="text-danger">*</span></label>
                            <input maxlength="10" type="text" formControlName="phone" name="phone_number" id="phone_number" class="form-control"
                            [ngStyle]="{ 'border-color': AddForm.get('phone')?.hasError('required') && AddForm.get('phone')?.touched ? 'red' : '#eaeaea' }"
                            >
                            <div
                            *ngIf="AddForm.get('phone')?.hasError('required') && AddForm.get('phone')?.touched"
                            class="text-danger">
                            Phone is required.
                          </div>

                          <div class="text-danger" *ngIf="AddForm.get('phone')?.hasError('pattern')">
                            Phone Number containing only numeric digits.
                          </div>
             
                          <div *ngIf="AddForm.get('phone').errors?.minlength" class="text-danger">
                            Mobile Number not exceed 10 numeric digits.
                          </div>
                        </div>
                    </div>
                    

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group mb-3">
                            <label>Gender <span class="text-danger">*</span></label><br>
                            <div class="p-2">
                                <input type="radio" formControlName="gender" name="gender" value="Male" [ngModel]="gender"> Male &nbsp;&nbsp;&nbsp;
                            <input type="radio" formControlName="gender" name="gender" value="Female"> Female
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group mb-3">
                            <label>Department  <span class="text-danger">*</span></label>
                            <input type="text" formControlName="department" name="Department" id="phone_number" class="form-control"
                            [ngStyle]="{ 'border-color': AddForm.get('department')?.hasError('required') && AddForm.get('department')?.touched ? 'red' : '#eaeaea' }"
                            >
                            <div
                            *ngIf="AddForm.get('department')?.hasError('required') && AddForm.get('department')?.touched"
                            class="text-danger">
                            Department is required.
                          </div>


                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group mb-3">
                            <label>Year of passing<span class="text-danger">*</span></label>
                            <input min="0" formControlName="YearOfpass" type="number" step="1"  maxlength="10" class="form-control"
                            [ngStyle]="{ 'border-color': AddForm.get('YearOfpass')?.hasError('required') && AddForm.get('YearOfpass')?.touched ? 'red' : '#eaeaea' }"
                            > 
                            <div
                            *ngIf="AddForm.get('YearOfpass')?.hasError('required') && AddForm.get('YearOfpass')?.touched"
                            class="text-danger">
                            passing Year is required.
                          </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group mb-3">
                            <label>Experience Year<span class="text-danger">*</span></label>
                            <input min="0" formControlName="YearOfExp" type="number" step="1"  maxlength="10" class="form-control"
                            [ngStyle]="{ 'border-color': AddForm.get('YearOfExp')?.hasError('required') && AddForm.get('YearOfExp')?.touched ? 'red' : '#eaeaea' }"
                            > 
                            <div
                            *ngIf="AddForm.get('YearOfExp')?.hasError('required') && AddForm.get('YearOfExp')?.touched"
                            class="text-danger">
                            EXperience Year is required.
                          </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group mb-3">
                            <label>Current Employer<span class="text-danger">*</span></label>
                            <input  type="text" formControlName="Employee" class="form-control"
                            [ngStyle]="{ 'border-color': AddForm.get('Employee')?.hasError('required') && AddForm.get('Employee')?.touched ? 'red' : '#eaeaea' }"
                            >
                            <div
                            *ngIf="AddForm.get('Employee')?.hasError('required') && AddForm.get('Employee')?.touched"
                            class="text-danger">
                            Employee is required.
                          </div>

                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group mb-3">
                            <label>Skill set<span class="text-danger">*</span></label>
                            <input type="text" formControlName="Skill" class="form-control"
                            [ngStyle]="{ 'border-color': AddForm.get('Skill')?.hasError('required') && AddForm.get('Skill')?.touched ? 'red' : '#eaeaea' }"
                            >
                            <div
                            *ngIf="AddForm.get('Skill')?.hasError('required') && AddForm.get('Skill')?.touched"
                            class="text-danger">
                            Skill set is required.
                          </div>

                        
                        </div>
                    </div>
                    

                    <div class="col-lg-12 col-md-12">
                        <div class="form-group mb-3">
                            <label>Upload Resume <span class="text-danger">*</span></label>
                                <div class="custom-file border border-light p-2 rounded-2">
                                  <input type="file" #inputGroupFile01 formControlName="resume" class="custom-file-input" id="inputGroupFile01" accept="application/pdf,application">
                                </div>
                                <div
                                *ngIf="AddForm.get('file')?.hasError('required') && AddForm.get('file')?.touched"
                                class="text-danger">
                                Last Name is required.
                              </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <button type="button" [disabled]="AddForm.invalid" class="default-btn" (click)="onadd()">Send Mail</button>
                        <p class="mt-2" style="font-size: 14px;" *ngIf="AddForm.invalid">Kindly fill all the mandatory (<span class="text-danger fw-bold">*</span>) fields  </p>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
