

<!-- Page Banner -->
<div class="page-banner-area item-bg4">
    <div class="container">
        <div class="page-banner-content">
            <h2>About us</h2>
            <ul>
                <li><a routerLink="/home-four">Home</a></li>
                <li>About us</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Banner -->

<!-- About -->
<section class="about-area ptb-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="about-image-warp image-three">

                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content warp">
                    <span>About Us</span>
                    <h3>Elevate Your Financial Future with Our Proven Strategies for Loans</h3>
                    <div class="bar" style="width: 100%;"></div>
                    <p style="font-size: 19px;">V for U Financial Services is one of the leading Loans Service Provider in Chennai. 
                    V for U Associates was founded in the year 2000 and evolved into a full-fledged financial service provider in 2010. 
                    With extensive industry experience and in-depth knowledge, the company has successfully built a customer base of 6000 and manages loans totaling 1500 crores.
                    Our services include Mortgage Loans, Car Loans, Business Loans, SME Loans and Home Loans, etc.
                    We are here to make your personal finance & help you get your loan. 
                    We are backed by rich industry experience and hence are capable of assisting our clients with all types of documentation work and guide them about the various schemes that are offered by the associated banks. Our qualified loan consultants see to it that the client gets the maximum benefit irrespective of the scheme selection.
                    We have created a sense of trust among our clients who always choose us to get reliable services.</p>

                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About -->

<!-- Services -->
<section class="services-area bg-0f051e pt-100 pb-70">
    <div class="container">

        <div class="row">
            <div class="col-lg-12 col-md-6">
                <div class="single-services-box">
                    <h3><a>Client Satisfaction</a></h3>
                    <p style="font-size: 19px;">We are a client-oriented organization that leaves no stone unturned to render complete
                        satisfaction to our valuable customers spread all around the nation.
                        The services are rendered to the client are in compliance with the prevailing industrial trends
                        and requirements.
                        Our services are timely completed and are rendered at pocket friendly rates to the customers.
                    </p>
                </div>
            </div>

            <div class="col-lg-12 col-md-6">
                <div class="single-services-box">
                    <h3><a>Our Team</a></h3>
                    <p style="font-size: 19px;">Team is the potency of any organization and the main reason behind its success and development.
                        Our team enables us to render effective services which also ensure that each of the requirements
                        of clients is met efficiently and in a timely manner.
                        Our team consists of experienced and qualified people, who have been hired after the stringent
                        recruitment process.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape1"><img src="assets/img/default-shape/default-shape1.png" alt="image"></div>
        <div class="shape2"><img src="assets/img/default-shape/default-shape2.png" alt="image"></div>
        <div class="shape3"><img src="assets/img/default-shape/default-shape3.png" alt="image"></div>
        <div class="shape4"><img src="assets/img/default-shape/default-shape4.png" alt="image"></div>
    </div>
</section><br><br>


<section >
     <h1 style="font-size: 25px;" class="text-center"><b>Our Business Partners</b></h1>
        <div class="slider">
            <div class="slide-track">
                <!--- Slider Track --->
                <div class="slide">
                    <img src="assets/img/slider_1.png" />
                </div>
                <div class="slide">
                    <img src="assets/img/slider_2.png" />
                </div>
                <div class="slide">
                    <img src="assets/img/slider_3.png" />
                </div>
    
                <div class="slide">
                    <img src="assets/img/slider_4.png" />
                </div>
                <div class="slide">
                    <img src="assets/img/slider_5.png" />
                </div>
                <div class="slide">
                    <img src="assets/img/slider_6.png" />
                </div>
                <div class="slide">
                    <img src="assets/img/slider_7.png" />
                </div>
                <div class="slide">
                    <img src="assets/img/slider_8.png" />
                </div>
                <div class="slide">
                    <img src="assets/img/slider_9.png" />
                </div>
                <div class="slide">
                    <img src="assets/img/slider_10.png" />
                </div>
                <div class="slide">
                    <img src="assets/img/slider_11.png" />
                </div>
                <div class="slide">
                    <img src="assets/img/slider_12.png" />
                </div>
                <div class="slide">
                    <img src="assets/img/slider_13.png" />
                </div>
    
                <!--- Repeat Slider Track --->
                <div class="slide">
                    <img src="assets/img/slider_1.png" />
                </div>
                <div class="slide">
                    <img src="assets/img/slider_2.png" />
                </div>
                <div class="slide">
                    <img src="assets/img/slider_3.png" />
                </div>
    
                <div class="slide">
                    <img src="assets/img/slider_4.png" />
                </div>
                <div class="slide">
                    <img src="assets/img/slider_5.png" />
                </div>
                <div class="slide">
                    <img src="assets/img/slider_6.png" />
                </div>
                <div class="slide">
                    <img src="assets/img/slider_7.png" />
                </div>
                <div class="slide">
                    <img src="assets/img/slider_8.png" />
                </div>
                <div class="slide">
                    <img src="assets/img/slider_9.png" />
                </div>
                <div class="slide">
                    <img src="assets/img/slider_10.png" />
                </div>
                <div class="slide">
                    <img src="assets/img/slider_11.png" />
                </div>
                <div class="slide">
                    <img src="assets/img/slider_12.png" />
                </div>
                <div class="slide">
                    <img src="assets/img/slider_13.png" />
                </div>
    
            </div>
        </div>
</section>


<!-- End Services -->

<!-- Team -->
<!-- <section class="team-area pb-70">
    <div class="container">
        <div class="section-title">
            <span>Team Member</span>
            <h2>Our Expert Team</h2>
            <div class="bar"></div>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team/team-6.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Alex Maxwel</h3>
                        <span>CEO & Founder</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team/team-7.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Justin Roberto</h3>
                        <span>Head Of Marketing</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team/team-8.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Louis Agassiz</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team/team-9.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Carl Anderson</h3>
                        <span>Android/IOS Developer</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Team -->

<!-- Pricing -->
<!-- <section class="pricing-area pb-70">
    <div class="container">
        <div class="section-title">
            <span>Pricing Plans</span>
            <h2>The Best Solutions for Our Clients</h2>
            <div class="bar"></div>
        </div>

        <div class="tab pricing-tab">
            <ul class="tabs">
                <li>
                    <a href="#">Monthly</a>
                </li>
                <li>
                    <a href="#">Yearly</a>
                </li>
            </ul>

            <div class="tab_content">
                <div class="tabs_item">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-pricing-box top-1">
                                <div class="pricing-header">
                                    <h3>Basic Plan</h3>
                                </div>
    
                                <div class="price">
                                    $24
                                </div>
    
                                <ul class="pricing-features">
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        SEO Audits
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        SEO Management
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        SEO Copywriting
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Link Building
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Site Migration
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Video Camplaigns
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Unlimited SEO Keywords
                                    </li>
                                </ul>
    
                                <div class="pricing-btn">
                                    <a href="#" class="default-btn">
                                        Get Started
                                    </a>
                                </div>
    
                                <div class="pricing-shape">
                                    <img src="assets/img/pricing-shape.png" alt="image">
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-4 col-md-6">
                            <div class="single-pricing-box">
                                <div class="pricing-header">
                                    <h3>Standard Plan</h3>
                                </div>
    
                                <div class="price">
                                    $59
                                </div>
    
                                <ul class="pricing-features">
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        SEO Audits
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        SEO Management
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        SEO Copywriting
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Link Building
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Site Migration
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Video Camplaigns
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Unlimited SEO Keywords
                                    </li>
                                </ul>
    
                                <div class="pricing-btn">
                                    <a href="#" class="default-btn">
                                        Get Started
                                    </a>
                                </div>
    
                                <div class="pricing-shape">
                                    <img src="assets/img/pricing-shape.png" alt="image">
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-4 col-md-6">
                            <div class="single-pricing-box top-2">
                                <div class="pricing-header">
                                    <h3>Premium Plan</h3>
                                </div>
    
                                <div class="price">
                                    $89
                                </div>
    
                                <ul class="pricing-features">
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        SEO Audits
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        SEO Management
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        SEO Copywriting
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Link Building
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Site Migration
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Video Camplaigns
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Unlimited SEO Keywords
                                    </li>
                                </ul>
    
                                <div class="pricing-btn">
                                    <a href="#" class="default-btn">
                                        Get Started
                                    </a>
                                </div>
    
                                <div class="pricing-shape">
                                    <img src="assets/img/pricing-shape.png" alt="image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-pricing-box top-1">
                                <div class="pricing-header">
                                    <h3>Basic Plan</h3>
                                </div>
    
                                <div class="price">
                                    $224
                                </div>
    
                                <ul class="pricing-features">
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        SEO Audits
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        SEO Management
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        SEO Copywriting
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Link Building
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Site Migration
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Video Camplaigns
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Unlimited SEO Keywords
                                    </li>
                                </ul>
    
                                <div class="pricing-btn">
                                    <a href="#" class="default-btn">
                                        Get Started
                                    </a>
                                </div>
    
                                <div class="pricing-shape">
                                    <img src="assets/img/pricing-shape.png" alt="image">
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-4 col-md-6">
                            <div class="single-pricing-box">
                                <div class="pricing-header">
                                    <h3>Standard Plan</h3>
                                </div>
    
                                <div class="price">
                                    $259
                                </div>
    
                                <ul class="pricing-features">
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        SEO Audits
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        SEO Management
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        SEO Copywriting
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Link Building
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Site Migration
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Video Camplaigns
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Unlimited SEO Keywords
                                    </li>
                                </ul>
    
                                <div class="pricing-btn">
                                    <a href="#" class="default-btn">
                                        Get Started
                                    </a>
                                </div>
    
                                <div class="pricing-shape">
                                    <img src="assets/img/pricing-shape.png" alt="image">
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-4 col-md-6">
                            <div class="single-pricing-box top-2">
                                <div class="pricing-header">
                                    <h3>Premium Plan</h3>
                                </div>
    
                                <div class="price">
                                    $289
                                </div>
    
                                <ul class="pricing-features">
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        SEO Audits
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        SEO Management
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        SEO Copywriting
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Link Building
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Site Migration
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Video Camplaigns
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Unlimited SEO Keywords
                                    </li>
                                </ul>
    
                                <div class="pricing-btn">
                                    <a href="#" class="default-btn">
                                        Get Started
                                    </a>
                                </div>
    
                                <div class="pricing-shape">
                                    <img src="assets/img/pricing-shape.png" alt="image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Pricing -->

<!-- Client -->
<!-- <section class="client-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Testimonials</span>
            <h2>Client’s Review</h2>
            <div class="bar"></div>
        </div>

        <div class="client-slider owl-carousel owl-theme">
            <div class="client-item">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley</p>
                <div class="client-info">
                    <img src="assets/img/client/client-1.jpg" alt="image">
                    <h3>Jacinda Meri</h3>
                    <span>CEO & Founder</span>
                </div>
            </div>

            <div class="client-item">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley</p>
                <div class="client-info">
                    <img src="assets/img/client/client-2.jpg" alt="image">
                    <h3>Miraj Alex</h3>
                    <span>Chief Executive Officer</span>
                </div>
            </div>

            <div class="client-item">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley</p>
                <div class="client-info">
                    <img src="assets/img/client/client-3.jpg" alt="image">
                    <h3>Edward Bold</h3>
                    <span>Web Developer</span>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Client -->

<!-- FunFacts -->
<!-- <section class="fun-facts-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact-box">
                    <div class="icon">
                        <i class="flaticon-checked"></i>
                    </div>
                    <h3><span class="odometer" data-count="950">00</span></h3>
                    <p>Completed Project</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact-box">
                    <div class="icon">
                        <i class="flaticon-happy"></i>
                    </div>
                    <h3><span class="odometer" data-count="850">00</span></h3>
                    <p>Happy Clients</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact-box">
                    <div class="icon">
                        <i class="flaticon-technical-support"></i>
                    </div>
                    <h3><span class="odometer" data-count="550">00</span></h3>
                    <p>Multi Service</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact-box">
                    <div class="icon">
                        <i class="flaticon-trophy"></i>
                    </div>
                    <h3><span class="odometer" data-count="750">00</span></h3>
                    <p>Winning Awards</p>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End FunFacts -->

<!-- Subscribe -->
<!-- <section class="subscribe-area ptb-100">
    <div class="container">
        <div class="subscribe-content">
            <span>Get Started Instantly!</span>
            <h2>Get Only New Update from this Newsletter</h2>
            <form class="newsletter-form">
                <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
                <button type="submit">Subscribe</button>
            </form>
        </div>
    </div>
</section> -->
<!-- End Subscribe -->

<!-- Partner -->
<!-- <div class="partner-area ptb-100">
    <div class="container">
        <div class="partner-slider owl-carousel owl-theme">
            <div class="partner-item">
                <img src="assets/img/partner/partner-1.png" alt="image">
            </div>

            <div class="partner-item">
                <img src="assets/img/partner/partner-2.png" alt="image">
            </div>

            <div class="partner-item">
                <img src="assets/img/partner/partner-3.png" alt="image">
            </div>

            <div class="partner-item">
                <img src="assets/img/partner/partner-4.png" alt="image">
            </div>

            <div class="partner-item">
                <img src="assets/img/partner/partner-5.png" alt="image">
            </div>

            <div class="partner-item">
                <img src="assets/img/partner/partner-6.png" alt="image">
            </div>
        </div>
    </div>
</div> -->
<!-- End Partner -->