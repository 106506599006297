<!-- Error -->
<section class="error-area ptb-100">
    <div class="container">
        <div class="error-content">
            <img src="assets/img/404-error.png" alt="error">
            <h3>Error 404 : Page Not Found</h3>
            <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
            <a routerLink="/home-four" class="default-btn">Go to Home</a>
        </div>
    </div>
</section>
<!-- End Error -->