<!-- fixed-button.component.html -->


<!-- <div class="others-options d-flex align-items-center justify-content-end fixed-button-container" >
    <div class="option-item">
        <a (click)="serviceClick('contact')" class="default-btn fixed-button rotate-90">Service Enquiry</a>
    </div>
</div> -->





  



<div class="d-flex flex-row flex-wrap ">
    <div class="col-lg-8 col-12">
        <div class="tab products-details-tab">
            <div class="slider">
                <div class="tabs_item animated fadeIn">
                    <div class="products-details-tab-content ms-md-5 ms-0 carousel slide" id="carouselExampleAutoplaying" data-bs-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active d-flex">
                                <img src="assets/img/MORTGAGE_LOAN.jpg" class="d-block w-100" alt="..." style="max-width: 100%; height: auto;">
                                <div class="carousel-caption d-flex flex-column justify-content-center h-100"></div>
                            </div>
                            <div class="carousel-item ">
                                <img src="assets/img/CAR_LOAN (3).jpg" class="d-block w-100" alt="..." style="max-width: 100%; height: auto;">
                                <div class="carousel-caption d-flex flex-column justify-content-center h-100"></div>
                            </div>
                            <div class="carousel-item ">
                                <img src="assets/img/HOME_LOAN.jpg" class="d-block w-100" alt="..." style="max-width: 100%; height: auto;">
                                <div class="carousel-caption d-flex flex-column justify-content-center h-100"></div>
                            </div>
                            
                            <div class="carousel-item ">
                                <img src="assets/img/SME_LOANS (3).jpg" class="d-block w-100" alt="..." style="max-width: 100%; height: auto;">
                                <div class="carousel-caption d-flex flex-column justify-content-center h-100"></div>
                            </div>
                            <div class="carousel-item ">
                                <img src="assets/img/BUSINESS_LOANS.jpg" class="d-block w-100" alt="..." style="max-width: 100%; height: auto;">
                                <div class="carousel-caption d-flex flex-column justify-content-center h-100"></div>
                            </div>
                            <div class="carousel-item ">
                                <img src="assets/img/LIFE_INSURANCE.jpg" class="d-block w-100" alt="..." style="max-width: 100%; height: auto;">
                                <div class="carousel-caption d-flex flex-column justify-content-center h-100"></div>
                            </div>
                            <div class="carousel-item ">
                                <img src="assets/img/GENERAL_INSURANCE.jpg" class="d-block w-100" alt="..." style="max-width: 100%; height: auto;">
                                <div class="carousel-caption d-flex flex-column justify-content-center h-100"></div>
                            </div>
                            <!-- Add similar adjustments for other carousel items -->
                
                        </div>
                        
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying"
                            data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying"
                            data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
                
            </div>
        </div>
    </div>

    <div class="col-lg-4 col-md-12 mt-5 mb-auto d-flex justify-content-center">
        <aside class="widget-area">
            <div class="widget widget_popular_products ms-4 me-5">
                <h3 class="widget-title">Secure Your Future: Enquiry About Loans Today!</h3>
                <p>Take the first step towards securing your future by enquiring about our loan options today. 
                    Let us help you achieve your financial goals.</p>
                <!-- <div class="others-options d-flex align-items-center justify-content-center">
                    <div class="option-item">
                        <a (click)="serviceClick('contact')" class="default-btn">Service Enquiry</a>
                    </div>
                </div> -->
            </div>
        </aside>
    </div>
    
</div><br>



<!-- Main Banner -->
<div class="main-banner">
    <div class="main-banner-item item-three">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row align-items-center" style="margin-top: -39px;">
                        <div class="col-lg-6 col-md-12">
                            <div class="main-banner-content ">
                                <h1>Welcome to V For U Financial Services </h1>
                                <p style="font-size: 19px;">We are engaged in providing services like Mortgage Loans, Car Loans, Business Loans,
                                    SME Loans, Insurance and Home Loans.
                                    The services are reliable and qualitative. We are an eminent entity, engaged in
                                    rendering services to our valuable customers.
                                    We are well known for the innovative ideas & concepts and secured loan with the
                                    property pledged as collateral with the bank/ housing finance company.
                                    We have a mission to fulfill the dreams of our clients.
                                    We believe in growing by forming mutually beneficial relations with the clients
                                    where we expand ourselves on a larger scale while offering total customer
                                    satisfaction to the clients.</p>
                                <!-- <div class="banner-btn">
                                    <a routerLink="/" class="default-btn">Get Services</a>
                                </div> -->
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="main-banner-image">
                                <img src="assets/img/home_intro.jpg" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="main-banner-shape">
        <div class="shape-9"><img src="assets/img/banner/banner-shape-6.png" alt="image"></div>
        <div class="shape-10"><img src="assets/img/banner/banner-shape-7.png" alt="image"></div>
        <div class="shape-11"><img src="assets/img/banner/banner-shape-8.png" alt="image"></div>
        <div class="shape-12"><img src="assets/img/banner/banner-shape-9.png" alt="image"></div>
        <div class="shape-13"><img src="assets/img/banner/banner-shape-10.png" alt="image"></div>
    </div>
</div>

<!-- Industries -->
<section class="industries-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Helping Businesses in All Domains</h2>
            <div class="bar"></div>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-digital-marketing-2"></i>
                    <h3>Mortgage Loans</h3>
                    <a (click)="scrollToDiv(mortgage)" ></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-digital-marketing-1"></i>
                    <h3>Car Loans</h3>
                    <a (click)="scrollToDiv(car)"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-bank"></i>
                    <h3>Home Loans</h3>
                    <a (click)="scrollToDiv(home)"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-employee"></i>
                    <h3>SME Loans</h3>
                    <a (click)="scrollToDiv(sme)"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-stock-market"></i>
                    <h3>Business Loans</h3>
                    <a (click)="scrollToDiv(business)"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-stethoscope"></i>
                    <h3>Life Insurance</h3>
                    <a (click)="scrollToDiv(life)"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-graduation-cap"></i>
                    <h3>General Insurance</h3>
                    <a (click)="scrollToDiv(general)"></a>
                </div>
            </div>

        </div>
    </div>
</section>
<!-- End Industries -->

<!-- Services routerLink="/services-details" -->
<section class="services-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-6" >
                <div class="single-services-box">
                    <div class="icon" #mortgage>
                        <i class="flaticon-digital-marketing-2"></i>
                    </div>
                    <h3><a >Mortgage Loans</a></h3>
                    <p style="font-size: 19px;">Choose from a range of options, including rental and income-based loans, to finance up to 50crs and grow your financial portfolio today.</p>
                    <a (click)="sample()"  class="services-btn" >Read More <i class="flaticon-right-arrow"></i></a>
                    
                </div>
            </div>

            <div class="col-lg-12 col-md-6" >
                <div class="single-services-box">
                    <div class="icon" #car>
                        <i class="flaticon-digital-marketing-1"></i>
                    </div>
                    <h3><a >Car Loans</a></h3>
                    <p style="font-size: 19px;">Upgrade your ride with a car loan that offers options for new and used vehicles, as well as refinancing and takeovers of existing loans.</p>
                    <a (click)="samplecar()" class="services-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-12 col-md-6" >
                <div class="single-services-box">
                    <div class="icon" #home>
                        <i class="flaticon-bank"></i>
                    </div>
                    <h3><a >Home Loans</a></h3>
                    <p style="font-size: 19px;">Achieve your dream home with the financial support of Home Loan. 
                    Whether it's a new property, construction, or renovation, we have you covered.</p>
                    <a (click)="samplehome()" fragment="home" class="services-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-12 col-md-6">
                <div class="single-services-box">
                    <div class="icon"  #sme>
                        <i class="flaticon-employee"></i>
                    </div>
                    <h3><a routerLink="/services-details">SME Loans</a></h3>
                    <p style="font-size: 19px;">Grow your small or medium business with our tailored loan options and competitive interest rates. 
                    Take your business to the next level with SME loan.</p>
                    <a (click)="samplesme()" class="services-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-12 col-md-6" >
                <div class="single-services-box">
                    <div class="icon" #business>
                        <i class="flaticon-stock-market"></i>
                    </div>
                    <h3><a routerLink="/services-details">Business Loans</a></h3>
                    <p style="font-size: 19px;">Take your business to the next level with our tailored loans designed to help your company grow and succeed. 
                    Invest in your future today.</p>
                    <a (click)="samplebusiness()" class="services-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-12 col-md-6" >
                <div class="single-services-box">
                    <div class="icon" #life>
                        <i class="flaticon-stethoscope"></i>
                    </div>
                    <h3><a routerLink="/services-details">Life Insurance</a></h3>
                    <p style="font-size: 19px;">Protect yourself and your loved ones with life insurance options that fit your needs. 
                    Experience peace of mind knowing you have the right coverage for all situations.</p>
                    <a (click)="samplelife()" class="services-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-12 col-md-6" >
                <div class="single-services-box">
                    <div class="icon" #general>
                        <i class="flaticon-graduation-cap"></i>
                    </div>
                    <h3><a routerLink="/services-details">General Insurance</a></h3>
                    <p style="font-size: 19px;">Agreement between a policyholder and insurer wherein the insurance company protects your valuable assets from fire, theft, burglary, or any other unfortunate accident.</p>
                    <a (click)="samplegeneral()" class="services-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape1"><img src="assets/img/default-shape/default-shape1.png" alt="image"></div>
        <div class="shape2"><img src="assets/img/default-shape/default-shape2.png" alt="image"></div>
        <div class="shape3"><img src="assets/img/default-shape/default-shape3.png" alt="image"></div>
        <div class="shape4"><img src="assets/img/default-shape/default-shape4.png" alt="image"></div>
    </div>
</section>

<section >
    <h1 style="font-size: 25px;" class="text-center"><b>Our Business Partners</b></h1>
       <div class="mover">
           <div class="move-track">
               <!--- Slider Track --->
               <div class="move">
                   <img src="assets/img/slider_1.png" />
               </div>
               <div class="move">
                   <img src="assets/img/slider_2.png" />
               </div>
               <div class="move">
                   <img src="assets/img/slider_3.png" />
               </div>
   
               <div class="move">
                   <img src="assets/img/slider_4.png" />
               </div>
               <div class="move">
                   <img src="assets/img/slider_5.png" />
               </div>
               <div class="move">
                   <img src="assets/img/slider_6.png" />
               </div>
               <div class="move">
                   <img src="assets/img/slider_7.png" />
               </div>
               <div class="move">
                   <img src="assets/img/slider_8.png" />
               </div>
               <div class="move">
                   <img src="assets/img/slider_9.png" />
               </div>
               <div class="move">
                   <img src="assets/img/slider_10.png" />
               </div>
               <div class="move">
                   <img src="assets/img/slider_11.png" />
               </div>
               <div class="move">
                   <img src="assets/img/slider_12.png" />
               </div>
               <div class="move">
                   <img src="assets/img/slider_13.png" />
               </div>
   
               <!--- Repeat Slider Track --->
               <div class="move">
                   <img src="assets/img/slider_1.png" />
               </div>
               <div class="move">
                   <img src="assets/img/slider_2.png" />
               </div>
               <div class="move">
                   <img src="assets/img/slider_3.png" />
               </div>
   
               <div class="move">
                   <img src="assets/img/slider_4.png" />
               </div>
               <div class="move">
                   <img src="assets/img/slider_5.png" />
               </div>
               <div class="move">
                   <img src="assets/img/slider_6.png" />
               </div>
               <div class="move">
                   <img src="assets/img/slider_7.png" />
               </div>
               <div class="move">
                   <img src="assets/img/slider_8.png" />
               </div>
               <div class="move">
                   <img src="assets/img/slider_9.png" />
               </div>
               <div class="move">
                   <img src="assets/img/slider_10.png" />
               </div>
               <div class="move">
                   <img src="assets/img/slider_11.png" />
               </div>
               <div class="move">
                   <img src="assets/img/slider_12.png" />
               </div>
               <div class="move">
                   <img src="assets/img/slider_13.png" />
               </div>
   
           </div>
       </div>
</section>
<!-- End Services -->


<!-- End Main Banner -->

<!-- Features -->
<!-- <section class="features-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="flaticon-promotion"></i>
                    </div>
                    <h3><a routerLink="/">Marketing Analysis</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                    <div class="number">
                        <span>1</span>
                    </div>
                    <div class="shape"><img src="assets/img/features-shape/features-shape-1.png" alt="image"></div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="flaticon-speed"></i>
                    </div>
                    <h3><a routerLink="/">Website Optimization</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                    <div class="number">
                        <span>2</span>
                    </div>
                    <div class="shape"><img src="assets/img/features-shape/features-shape-1.png" alt="image"></div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="flaticon-email"></i>
                    </div>
                    <h3><a routerLink="/">Email Marketing</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                    <div class="number">
                        <span>3</span>
                    </div>
                    <div class="shape"><img src="assets/img/features-shape/features-shape-1.png" alt="image"></div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="flaticon-network"></i>
                    </div>
                    <h3><a routerLink="/">Social Media Marketing</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                    <div class="number">
                        <span>4</span>
                    </div>
                    <div class="shape"><img src="assets/img/features-shape/features-shape-1.png" alt="image"></div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Features -->

<!-- About -->
<!-- <section class="about-area pb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about/about-4.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content style-width">
                    <span>About Us</span>
                    <h3>We Help Companies to Generate Leads and Increase in Sales</h3>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt labore  dolore magna aliqua.</p>

                    <div class="about-inner-content">
                        <div class="icon">
                            <i class="flaticon-check"></i>
                        </div>
                        <h4>Online Presence</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>

                    <div class="about-inner-content">
                        <div class="icon">
                            <i class="flaticon-check"></i>
                        </div>
                        <h4>Marketing Strategy</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>

                    <div class="about-inner-content">
                        <div class="icon">
                            <i class="flaticon-check"></i>
                        </div>
                        <h4>Promote local Sale</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>

                    <div class="about-btn">
                        <a routerLink="/" class="default-btn">Learn More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>    -->
<!-- End About -->

<!-- Services -->
<!-- <section class="services-area bg-0f051e pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Services we provided</span>
            <h2>Our Digital Marketing Services</h2>
            <div class="bar"></div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-code"></i>
                    </div>
                    <h3><a routerLink="/services-details">Website Development</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incutu  labore et dolore magna aliqua.</p>
                    <a routerLink="/services-details" class="services-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-vector"></i>
                    </div>
                    <h3><a routerLink="/services-details">Web Design</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incutu  labore et dolore magna aliqua.</p>
                    <a routerLink="/services-details" class="services-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-network"></i>
                    </div>
                    <h3><a routerLink="/services-details">Social Media Marketing</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incutu  labore et dolore magna aliqua.</p>
                    <a routerLink="/services-details" class="services-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-search"></i>
                    </div>
                    <h3><a routerLink="/services-details">Content Marketing</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incutu  labore et dolore magna aliqua.</p>
                    <a routerLink="/services-details" class="services-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-digital-marketing-1"></i>
                    </div>
                    <h3><a routerLink="/services-details">Digital Media & PPC</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incutu  labore et dolore magna aliqua.</p>
                    <a routerLink="/services-details" class="services-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-cpu"></i>
                    </div>
                    <h3><a routerLink="/services-details">Technology</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incutu  labore et dolore magna aliqua.</p>
                    <a routerLink="/services-details" class="services-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape1"><img src="assets/img/default-shape/default-shape1.png" alt="image"></div>
        <div class="shape2"><img src="assets/img/default-shape/default-shape2.png" alt="image"></div>
        <div class="shape3"><img src="assets/img/default-shape/default-shape3.png" alt="image"></div>
        <div class="shape4"><img src="assets/img/default-shape/default-shape4.png" alt="image"></div>
    </div>
</section> -->
<!-- End Services -->

<!-- Quote -->
<!-- <section class="quote-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="quote-form">
                    <div class="content">
                        <h3>Get A free Rainz Quote Now</h3>
                    </div>

                    <form>
                        <div class="form-group mb-3">
                            <input type="text" class="form-control" id="Name" placeholder="Name">
                        </div>

                        <div class="form-group mb-3">
                            <input type="email" class="form-control" id="Email" placeholder="Email">
                        </div>

                        <div class="form-group mb-3">
                            <input type="phone" class="form-control" id="Phone" placeholder="Phone">
                        </div>

                        <div class="form-group mb-3">
                            <select>
                                <option>Company</option>
                                <option>Ellison Estate</option>
                                <option>Four Fairfield Pond</option>
                                <option>Les Palais Bulles</option>
                                <option>Villa Les Cèdres</option>
                                <option>Villa Leopolda</option>
                                <option>Buckingham Palace</option>
                            </select>	
                        </div>

                        <div class="form-group mb-3">
                            <textarea name="message" class="form-control" placeholder="Message"></textarea>
                        </div>
                            
                        <div class="quote-btn">
                            <button type="submit" class="default-btn">Send Message</button>
                        </div>
                    </form>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="quote-image">
                    <img src="assets/img/quote/quote-1.png" alt="image">
                </div>
            </div>
        </div>
    </div>

    <div class="quote-shape">
        <div class="shape-1"><img src="assets/img/quote/quote-shape-1.png" alt="image"></div>
        <div class="shape-2"><img src="assets/img/quote/quote-shape-2.png" alt="image"></div>
    </div>
</section> -->
<!-- End Quote -->

<!-- Industries -->
<!-- <section class="industries-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Industries We Work For</span>
            <h2>Helping Businesses in All Domains</h2>
            <div class="bar"></div>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-digital-marketing-2"></i>
                    <h3>Digital Marketing</h3>
                    <a routerLink="/"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-bank"></i>
                    <h3>Banking Services</h3>
                    <a routerLink="/"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-graduation-cap"></i>
                    <h3>Education Services</h3>
                    <a routerLink="/"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-stock-market"></i>
                    <h3>Business Consult</h3>
                    <a routerLink="/"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-employee"></i>
                    <h3>Entrepreneur</h3>
                    <a routerLink="/"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-waiter"></i>
                    <h3>Restaurant</h3>
                    <a routerLink="/"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-stethoscope"></i>
                    <h3>Health Services</h3>
                    <a routerLink="/"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-user"></i>
                    <h3>Social Network</h3>
                    <a routerLink="/"></a>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Industries -->

<!-- Portfolio -->
<!-- <section class="portfolio-area bg-f1f1f1 pt-100 pb-70">
    <div class="container-fluid">
        <div class="section-title">
            <span>Portfolio Showcase</span>
            <h2>Our Impressive Portfolio</h2>
            <div class="bar"></div>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-portfolio">
                    <div class="portfolio-image">
                        <img src="assets/img/portfolio/portfolio-1.jpg" alt="image">
                    </div>
                    <div class="portfolio-content">
                        <a routerLink="/portfolio-details"><i class='bx bx-plus'></i></a>
                        <h3><a routerLink="/portfolio-details">Creative Web Design</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-portfolio">
                    <div class="portfolio-image">
                        <img src="assets/img/portfolio/portfolio-2.jpg" alt="image">
                    </div>
                    <div class="portfolio-content">
                        <a routerLink="/portfolio-details"><i class='bx bx-plus'></i></a>
                        <h3><a routerLink="/portfolio-details">Web Development</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-portfolio">
                    <div class="portfolio-image">
                        <img src="assets/img/portfolio/portfolio-3.jpg" alt="image">
                    </div>
                    <div class="portfolio-content">
                        <a routerLink="/portfolio-details"><i class='bx bx-plus'></i></a>
                        <h3><a routerLink="/portfolio-details">Web Application</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-portfolio">
                    <div class="portfolio-image">
                        <img src="assets/img/portfolio/portfolio-4.jpg" alt="image">
                    </div>
                    <div class="portfolio-content">
                        <a routerLink="/portfolio-details"><i class='bx bx-plus'></i></a>
                        <h3><a routerLink="/portfolio-details">Dashboard App</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-portfolio">
                    <div class="portfolio-image">
                        <img src="assets/img/portfolio/portfolio-large-1.jpg" alt="image">
                    </div>
                    <div class="portfolio-content">
                        <a routerLink="/portfolio-details"><i class='bx bx-plus'></i></a>
                        <h3><a routerLink="/portfolio-details">Digital Agency</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-portfolio">
                    <div class="portfolio-image">
                        <img src="assets/img/portfolio/portfolio-large-2.jpg" alt="image">
                    </div>
                    <div class="portfolio-content">
                        <a routerLink="/portfolio-details"><i class='bx bx-plus'></i></a>
                        <h3><a routerLink="/portfolio-details">Website Development</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-portfolio">
                    <div class="portfolio-image">
                        <img src="assets/img/portfolio/portfolio-large-3.jpg" alt="image">
                    </div>
                    <div class="portfolio-content">
                        <a routerLink="/portfolio-details"><i class='bx bx-plus'></i></a>
                        <h3><a routerLink="/portfolio-details">Logo & Branding</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-portfolio">
                    <div class="portfolio-image">
                        <img src="assets/img/portfolio/portfolio-large-4.jpg" alt="image">
                    </div>
                    <div class="portfolio-content">
                        <a routerLink="/portfolio-details"><i class='bx bx-plus'></i></a>
                        <h3><a routerLink="/portfolio-details">App Development</a></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape1"><img src="assets/img/default-shape/default-shape1.png" alt="image"></div>
        <div class="shape2"><img src="assets/img/default-shape/default-shape2.png" alt="image"></div>
        <div class="shape3"><img src="assets/img/default-shape/default-shape3.png" alt="image"></div>
        <div class="shape4"><img src="assets/img/default-shape/default-shape4.png" alt="image"></div>
    </div>
</section> -->
<!-- End Portfolio -->

<!-- Client -->
<!-- <section class="client-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Testimonials</span>
            <h2>Client’s Review</h2>
            <div class="bar"></div>
        </div>

        <div class="client-slider owl-carousel owl-theme">
            <div class="client-item">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley</p>
                <div class="client-info">
                    <img src="assets/img/client/client-1.jpg" alt="image">
                    <h3>Jacinda Meri</h3>
                    <span>CEO & Founder</span>
                </div>
            </div>

            <div class="client-item">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley</p>
                <div class="client-info">
                    <img src="assets/img/client/client-2.jpg" alt="image">
                    <h3>Miraj Alex</h3>
                    <span>Chief Executive Officer</span>
                </div>
            </div>

            <div class="client-item">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley</p>
                <div class="client-info">
                    <img src="assets/img/client/client-3.jpg" alt="image">
                    <h3>Edward Bold</h3>
                    <span>Web Developer</span>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Client -->

<!-- Blog -->
<!-- <section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Blog</span>
            <h2>Latest News From Blog</h2>
            <div class="bar"></div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <div class="image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/blog-1.jpg" alt="image">
                        </a>
                    </div>
                    <div class="content">
                        <span>20 March, 2023</span>
                        <h3><a routerLink="/blog-details">7 Great Tips For Earn More Money From Digital Industry</a></h3>
                        <a routerLink="/blog-details" class="blog-btn">Read More <i class='bx bx-chevrons-right'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <div class="image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/blog-2.jpg" alt="image">
                        </a>
                    </div>
                    <div class="content">
                        <span>25 March, 2023</span>
                        <h3><a routerLink="/blog-details">How To Boost Your Digital Marketing Agency</a></h3>
                        <a routerLink="/blog-details" class="blog-btn">Read More <i class='bx bx-chevrons-right'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <div class="image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/blog-3.jpg" alt="image">
                        </a>
                    </div>
                    <div class="content">
                        <span>28 March, 2023</span>
                        <h3><a routerLink="/blog-details">The Billionaire Guide On Design That will Get You Rich</a></h3>
                        <a routerLink="/blog-details" class="blog-btn">Read More <i class='bx bx-chevrons-right'></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape1"><img src="assets/img/default-shape/default-shape1.png" alt="image"></div>
        <div class="shape2"><img src="assets/img/default-shape/default-shape2.png" alt="image"></div>
        <div class="shape3"><img src="assets/img/default-shape/default-shape3.png" alt="image"></div>
        <div class="shape4"><img src="assets/img/default-shape/default-shape4.png" alt="image"></div>
    </div>
</section> -->
<!-- End Blog -->

<!-- Subscribe -->
<!-- <section class="subscribe-area ptb-100">
    <div class="container">
        <div class="subscribe-content">
            <span>Get Started Instantly!</span>
            <h2>Get Only New Update from this Newsletter</h2>
            <form class="newsletter-form" data-toggle="validator">
                <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
                <button type="submit">Subscribe</button>
            </form>
        </div>
    </div>
</section> -->
<!-- End Subscribe -->

<!-- Partner -->
<!-- <div class="partner-area ptb-100">
    <div class="container">
        <div class="partner-slider owl-carousel owl-theme">
            <div class="partner-item">
                <img src="assets/img/partner/partner-1.png" alt="image">
            </div>

            <div class="partner-item">
                <img src="assets/img/partner/partner-2.png" alt="image">
            </div>

            <div class="partner-item">
                <img src="assets/img/partner/partner-3.png" alt="image">
            </div>

            <div class="partner-item">
                <img src="assets/img/partner/partner-4.png" alt="image">
            </div>

            <div class="partner-item">
                <img src="assets/img/partner/partner-5.png" alt="image">
            </div>

            <div class="partner-item">
                <img src="assets/img/partner/partner-6.png" alt="image">
            </div>
        </div>
    </div>
</div> -->
<!-- End Partner -->



<!-- <div class="row">
            <div class="col-lg-8 col-md-12">
                 <div class="tab products-details-tab">
                    <div class="slider">
                        <div class="tabs_item">
                            <div class="products-details-tab-content">
                                <img src="assets/img/header_2.png" class="d-block w-100  " alt="" style="max-width: 100%; height: auto;">
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-12 me-0 p-0">
                <aside class="widget-area">
                    <section class="widget widget_popular_products">
                        <h3 class="widget-title">Popular Products</h3>
                    </section>
                </aside>
            </div>
        </div> -->

