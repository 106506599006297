import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Excelservice } from 'src/app/services/excel.service';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']


})


export class TestimonialsComponent {

  emi_list: any[] = [];
  max = 2000000000;
  min = 50000000;
  showTicks = false;
  step = 50000000;
  thumbLabel = false;
  Value: number = 500000;

  maxRate: any = 30;
  minRate = 1;
  showTicksRate = false;
  stepRate = 1;
  thumbLabelRate = false;
  ValueRate: any = 1.00;

  maxTenure = 30;
  minTenure = 1;
  showTicksTenure = false;
  stepTenure = 1;
  thumbLabelTenure = false;
  ValueTenure: number = 1;
  schedule = false
  loanAmount: number;
  interestRate: number;
  loanTerm: number;
  emi: number;
  progressValue: number = 0;
  emiSchedule: any[] = [];

  constructor(private router: Router,
    private excelservice: Excelservice,) { }

  sample = false
  calculateEMI(principal: number,
    rate: number, tenure: number): void {
    // const principal = this.Value;
    // const monthlyInterestRate = (this.ValueRate / 100) / 12;
    // const numberOfPayments = this.ValueTenure * 12;

    // let remainingValue = numberOfPayments;
    // this.emi = (principal * monthlyInterestRate) / (1 - Math.pow(1 + monthlyInterestRate, -remainingValue));
    // this.progressValue = ((numberOfPayments - remainingValue) / numberOfPayments) * 100;
    let emi: number;
    let interest: number;
    let balance: number = principal;
    this.schedule = true
    this.emiSchedule = []



    rate = rate / (12 * 100);
    tenure = tenure * 12;

    this.emi = (principal * rate * Math.pow(1 + rate, tenure)) / (Math.pow(1 + rate, tenure) - 1);
    emi = (principal * rate * Math.pow(1 + rate, tenure)) / (Math.pow(1 + rate, tenure) - 1);

    for (let i = 0; i < tenure; i++) {
      interest = balance * rate;
      balance = balance - (emi - interest);
      this.emiSchedule.push({
        interest: interest,
        emi: emi,
        balance: balance
      });
    }
    this.sample = true

  }

  calculateEmi(principal: number, rate: number, time: number): number {
    const r = rate / (12 * 100); // one month interest
    const t = time * 12; // one month period

    const emi =
      (principal * r * Math.pow(1 + r, t)) / (Math.pow(1 + r, t) - 1);

    return emi;
  }

  serviceClick(value: string) {
    var url = '/contact-1';
    this.router.navigateByUrl(url).then(() => {
      const element = document.getElementById(value);
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    });
  }
  viewschedule(principal: number, rate: number, tenure: number): void {
    this.schedule = true
  }
  scrollToDiv(target: HTMLElement) {
    this.schedule = true;
    // Scroll to the target element smoothly

    target.scrollIntoView();
  }



  updateSlider(type: string): void {
    switch (type) {
      case 'amount':
        this.Value = Number(this.Value);
        break;
      case 'rate':
        this.ValueRate = Number(this.ValueRate);
        break;
      case 'tenure':
        this.ValueTenure = Number(this.ValueTenure);
        break;
    }
  }

  updateInput(type: string, event: any): void {
    switch (type) {
      case 'amount':
        this.Value = Number(event.value);
        break;
      case 'rate':
        this.ValueRate = Number(event.value);
        break;
      case 'tenure':
        this.ValueTenure = Number(event.value);
        break;
    }
  }

  validateRate() {
    if (this.ValueRate > 30) {
      // Display an error message or take appropriate action
      console.log("Error: Interest rate cannot be more than 30%");
      // You may also reset the value or prevent further action
      this.ValueRate = 30;
    }


  }

  validateTenure() {
    if (this.ValueTenure > 30) {
      // Display an error message or take appropriate action
      console.log("Error: Tenure cannot be more than 30 years");
      // You may also reset the value or prevent further action
      this.ValueTenure = 30;
    }
  }


  validateValue() {
    if (this.Value > 20000000) {
      // Display an error message or take appropriate action
      console.log("Error: value cannot be more than 20000000");
      // You may also reset the value or prevent further action
      this.Value = 20000000;
    }
  }

  // downloadpdf(): void {
  //   debugger
  //   let index = 1;
  //   const PaymentReport = this.emiSchedule.map(item => ({
  //     Month: index++ || '',
  //     EMI: item.emi || '',
  //     Interest: item.interest || '',
  //     balance: item.balance || '',
  //   }));


  //   this.excelservice.exportAsExcelFile(PaymentReport, 'Repayment_schedule');
  // }

  downloadpdf() {
    debugger
    let index = 1;
    const doc = new jsPDF() as any
    var prepare: any[][] = [];
    this.emiSchedule.forEach(item => {
      var tempObj = [];
      tempObj.push(index++);
      tempObj.push(parseFloat(item.emi).toFixed(2));
      tempObj.push(parseFloat(item.interest).toFixed(2));
      let floatValue = parseFloat(item.balance);
      let roundedValue = floatValue.toFixed(2);
      let nonNegativeValue = Math.abs(parseFloat(roundedValue));
      let finalValue = nonNegativeValue < 0.005 ? 0.00 : nonNegativeValue;
      tempObj.push(finalValue.toFixed(2));
      prepare.push(tempObj);
    });

    console.log(prepare);

    autoTable(doc, {
      head: [['Month', 'EMI', 'Interest', 'Outstanding Balance']],
      body: prepare,
      styles: { valign: 'middle', halign: 'center' }
    });

    doc.save('Repayment' + '.pdf');
  }

 

incrementValue() {
    if (this.ValueRate < 30) { // Adjust the maximum limit if needed
        this.ValueRate = +(this.ValueRate + 0.01).toFixed(2);
    }
}

decrementValue() {
    if (this.ValueRate > 1) { // Adjust the minimum limit if needed
        this.ValueRate = +(this.ValueRate - 0.01).toFixed(2);
    }
}

incrementYear() {
  if (this.ValueTenure < 30) { // Adjust the maximum limit if needed
      this.ValueTenure += 1;
  }
}

decrementYear() {
  if (this.ValueTenure > 1) { // Adjust the minimum limit if needed
      this.ValueTenure -= 1;
  }
}

allowOnlyNumbers(event: any) {
  const inputChar = String.fromCharCode(event.charCode);

  // Allow digits and a single decimal point
  if (!/^\d*\.?\d*$/.test(inputChar)) {
    event.preventDefault();
  }
}




}