<!-- Page Banner -->
<div class="page-banner-area item-bg3">
    <div class="container">
        <div class="page-banner-content">
            <h2>Features</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Features</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Banner -->

<!-- Features -->
<section class="features-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-width">
            <span>Care Features</span>
            <h2>Create Awesome Service With Our Tools</h2>
            <div class="bar"></div>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="features-image-warp">
                    <img src="assets/img/features/features-2.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="single-features-box top-1">
                            <div class="icon">
                                <i class="flaticon-promotion"></i>
                            </div>
                            <h3><a routerLink="/">Marketing Analysis</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-features-box top-2">
                            <div class="icon">
                                <i class="flaticon-speed"></i>
                            </div>
                            <h3><a routerLink="/">Website Optimization</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-features-box top-3">
                            <div class="icon">
                                <i class="flaticon-email"></i>
                            </div>
                            <h3><a routerLink="/">Email Marketing</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-features-box top-4">
                            <div class="icon">
                                <i class="flaticon-network"></i>
                            </div>
                            <h3><a routerLink="/">Social Media Marketing</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Features -->

<!-- Features -->
<section class="features-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="features-inner-content">
                    <span>Features</span>
                    <h3>Digital Marketing</h3>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra.</p>
                    <ul class="features-list">
                        <li><span><i class="flaticon-checked"></i> SEO Marketing</span></li>
                        <li><span><i class="flaticon-checked"></i> Social Marketing</span></li>
                        <li><span><i class="flaticon-checked"></i> Email Marketing</span></li>
                        <li><span><i class="flaticon-checked"></i> Facebook Marketing</span></li>
                        <li><span><i class="flaticon-checked"></i> Youtube Marketing</span></li>
                        <li><span><i class="flaticon-checked"></i> Page Ranking</span></li>
                    </ul>
                    <div class="features-btn">
                        <a routerLink="/" class="default-btn">Learn More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="features-image">
                    <img src="assets/img/features/features-1.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Features -->

<!-- Subscribe -->
<section class="subscribe-area ptb-100">
    <div class="container">
        <div class="subscribe-content">
            <span>Get Started Instantly!</span>
            <h2>Get Only New Update from this Newsletter</h2>
            <form class="newsletter-form">
                <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
                <button type="submit">Subscribe</button>
            </form>
        </div>
    </div>
</section>
<!-- End Subscribe -->