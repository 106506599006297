<!-- Page Banner -->
<div class="page-banner-area item-bg1">
    <div class="container">
        <div class="page-banner-content">
            <h2>Shop List</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Shop List</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Banner -->

<!-- Products -->
<section class="product-area pt-100 pb-100">
    <div class="container">
        <div class="rainz-grid-sorting row align-items-center">
            <div class="col-lg-6 col-md-6 result-count">
                <p>We found <span class="count">10</span> products available for you</p>
            </div>

            <div class="col-lg-6 col-md-6 ordering">
                <div class="select-box">
                    <label>Sort By:</label>
                    <select>
                        <option>Default</option>
                        <option>Popularity</option>
                        <option>Latest</option>
                        <option>Price: low to high</option>
                        <option>Price: high to low</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-products-box">
                    <div class="products-image">
                        <a routerLink="/products-details">
                            <img src="assets/img/shop/shop1.jpg" alt="image">
                        </a>
                    </div>
                    <div class="products-content">
                        <h3><a routerLink="/products-details">Note Book Mockup</a></h3>
                        <div class="price">
                            <span class="old-price">$125</span>
                            <span class="new-price">$120</span>
                        </div>
                        <div class="star-rating">
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                        </div>
                        <a routerLink="/cart" class="add-to-cart">Add to Cart</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-products-box">
                    <div class="products-image">
                        <a routerLink="/products-details">
                            <img src="assets/img/shop/shop2.jpg" alt="image">
                        </a>
                    </div>
                    <div class="products-content">
                        <h3><a routerLink="/products-details">Motivational Book Cover</a></h3>
                        <div class="price">
                            <span class="old-price">$225</span>
                            <span class="new-price">$220</span>
                        </div>
                        <div class="star-rating">
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                        </div>
                        <a routerLink="/cart" class="add-to-cart">Add to Cart</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-products-box">
                    <div class="products-image">
                        <a routerLink="/products-details">
                            <img src="assets/img/shop/shop3.jpg" alt="image">
                        </a>
                    </div>
                    <div class="products-content">
                        <h3><a routerLink="/products-details">Book Cover Softcover</a></h3>
                        <div class="price">
                            <span class="old-price">$265</span>
                            <span class="new-price">$270</span>
                        </div>
                        <div class="star-rating">
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                        </div>
                        <a routerLink="/cart" class="add-to-cart">Add to Cart</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-products-box">
                    <div class="products-image">
                        <a routerLink="/products-details">
                            <img src="assets/img/shop/shop4.jpg" alt="image">
                        </a>
                    </div>
                    <div class="products-content">
                        <h3><a routerLink="/products-details">Stop and Take a Second</a></h3>
                        <div class="price">
                            <span class="old-price">$125</span>
                            <span class="new-price">$120</span>
                        </div>
                        <div class="star-rating">
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                        </div>
                        <a routerLink="/cart" class="add-to-cart">Add to Cart</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-products-box">
                    <div class="products-image">
                        <a routerLink="/products-details">
                            <img src="assets/img/shop/shop5.jpg" alt="image">
                        </a>
                    </div>
                    <div class="products-content">
                        <h3><a routerLink="/products-details">Real Life Fairytale</a></h3>
                        <div class="price">
                            <span class="old-price">$225</span>
                            <span class="new-price">$220</span>
                        </div>
                        <div class="star-rating">
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                        </div>
                        <a routerLink="/cart" class="add-to-cart">Add to Cart</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-products-box">
                    <div class="products-image">
                        <a routerLink="/products-details">
                            <img src="assets/img/shop/shop6.jpg" alt="image">
                        </a>
                    </div>
                    <div class="products-content">
                        <h3><a routerLink="/products-details">Running From Me</a></h3>
                        <div class="price">
                            <span class="old-price">$265</span>
                            <span class="new-price">$270</span>
                        </div>
                        <div class="star-rating">
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                        </div>
                        <a routerLink="/cart" class="add-to-cart">Add to Cart</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <a routerLink="/shop-1" class="prev page-numbers"><i class="flaticon-left-arrow"></i></a>
                    <a routerLink="/shop-1" class="page-numbers">1</a>
                    <span class="page-numbers current" aria-current="page">2</span>
                    <a routerLink="/shop-1" class="page-numbers">3</a>
                    <a routerLink="/shop-1" class="page-numbers">4</a>
                    <a routerLink="/shop-1" class="next page-numbers"><i class="flaticon-next"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Products -->